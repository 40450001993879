import { Trans } from 'react-i18next';
import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Top,
  Center,
  Bottom
} from '../../layouts/MainLayout/MainLayout';
import { MainTitle, InstructionCard } from '../../components';

const FactorySettingsInstructions = (props) => {
  const appContext = useContext(AppContext);
  const { dispatch } = appContext;

  useEffect(() => {
    dispatch({ type: 'progress', payload: 'step4' });
  }, [dispatch]);
  return (
    <>
      <MainLayout
        progress_bar
        exit
        nextButton={{
          text: <Trans i18nKey="next_button" />,
          to: process.env.PUBLIC_URL + `/open-drawer`
        }}
        backButton={{
          to: process.env.PUBLIC_URL + '/timer/operating-system'
        }}
      >
        <Top></Top>
        <Center>
          <div>
            <MainTitle className="mb-5 pb-5 text-center">
              <Trans i18nKey="factory_settings.Before putting your smartphone in the drawer" />
            </MainTitle>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <InstructionCard title={props.os} id={props.os} />
          </div>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default FactorySettingsInstructions;
