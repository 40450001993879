import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Top,
  Center,
  Bottom
} from '../../layouts/MainLayout/MainLayout';
import {
  MainCard,
  Input,
  CheckBox,
  PrivacyPolicy,
  PrivacyPolicyOrangepl,
  PrivacyPolicyEl,
  Notification
} from '../../components';
import axios from 'axios';
import { navigate } from '@gatsbyjs/reach-router';

const UserInformation = (props) => {
  const { t, i18n } = props;
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;

  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'user-information' });
    dispatch({ type: 'progress', payload: 'step7' });
    if (!state.userInformation.payment_method) {
      axios
        .get(
          `https://session-service.greenpanda.io/voucher/code/${state.flow.partner.code}/${state.deviceInfo.imei}`
        )
        .then((res) => {
          console.log(res.data);
          dispatch({
            type: 'input',
            payload: {
              payment_value: res.data.code,
              payment_method: 'voucher'
            }
          });
        });
    }
  }, []);

  // const [email, setEmail] = useState('');
  // const [name, setName] = useState('');
  // const [phone, setPhone] = useState('');
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState('none');
  // useEffect(() => {
  //     if (state.userInformation.email) {
  //         if (/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(state.userInformation.email))
  //             setEmail(true);
  //         else
  //             setEmail(false);
  //     }
  // }, [state.userInformation.email]);

  // useEffect(() => {
  //     if (state.userInformation.name) {
  //         if (state.userInformation.name.length > 0)
  //             setName(true);
  //         else
  //             setName(false);
  //     }
  // }, [state.userInformation.name]);

  // useEffect(() => {
  //     if (state.userInformation.phone) {
  //         // if (state.userInformation.phone.match(/\d{10,15}/))
  //         setPhone(true)
  //         // else
  //         // setPhone(false)
  //     }
  // }, [state.userInformation.phone]);

  useEffect(() => {
    if (state.checkbox)
      if (state.checkbox.privacy_policy) setDisabled(false);
      else setDisabled(true);
  }, [state.checkbox]);

  const onNextButton = () => {
    if (
      state.userInformation.email === '' ||
      state.userInformation.name === '' ||
      state.userInformation.phone === '' ||
      disabled
    ) {
      if (display === 'none') {
        setDisplay('flex');
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 5);
        setTimeout(() => {
          setDisplay('none');
        }, 6000);
      }
    } else {
      setTimeout(() => {
        navigate(process.env.PUBLIC_URL + '/confirm-payment', {
          replace: true
        });
      }, 100);
    }
  };
  return (
    <>
      <MainLayout
        progress_bar
        exit
        nextButton={{
          text: <Trans i18nKey="next_button" />,
          to: '#',
          onClick: () => onNextButton()
        }}
        backButton={{
          to: process.env.PUBLIC_URL + state.flow.user_information.back_button
        }}
      >
        <Top></Top>
        <Center>
          <MainCard className="flex-column center" large>
            <div className="fs30 fw600 lh36 text-center mb-5 pb-5">
              <Trans i18nKey="user_information.title" />
            </div>
            <div>
              <Input
                label={<Trans i18nKey="user_information.full_name" />}
                field="name"
                placeholder={t('user_information.enter_fullname')}
              />
              <div className="my-5">
                <Input
                  label="EMAIL"
                  field="email"
                  placeholder={t('user_information.enter_email')}
                />
              </div>
              <Input
                label={<Trans i18nKey="user_information.phone" />}
                field="phone"
                placeholder={t('user_information.enter_phone')}
              />
              <div className="center mt-5 pt-3">
                <CheckBox field="privacy_policy" />
                <div
                  style={{ width: '100%' }}
                  className="fs20 fw600 text-start ms-4"
                >
                  <Trans i18nKey="user_information.agree" />{' '}
                  <u onClick={() => setShow(true)}>
                    <Trans i18nKey="user_information.privacy_policy" />
                  </u>
                </div>
              </div>
            </div>
            <Notification type="error" loading={loading} display={display}>
              <div>
                <Trans i18nKey="user_information.notification" />
              </div>
            </Notification>
            )
          </MainCard>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
      {i18n.language === 'en' && (
        <PrivacyPolicy show={show} onHide={() => setShow(false)} />
      )}
      {i18n.language === 'pl' && (
        <PrivacyPolicyOrangepl show={show} onHide={() => setShow(false)} />
      )}
      {i18n.language === 'el' && (
        <PrivacyPolicyEl show={show} onHide={() => setShow(false)} />
      )}
    </>
  );
};

export default withTranslation()(UserInformation);
