import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center } from '../../layouts/MainLayout/MainLayout';
import { MainImage, MainTitle, ExitPopup } from '../../components';
import { navigate } from '@gatsbyjs/reach-router';
import io from 'socket.io-client';

const Identification = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const deviceInfo = state.deviceInfo ? state.deviceInfo : '';
  const brand = state.deviceInfo ? state.deviceInfo.brand : '';
  const model = state.deviceInfo ? state.deviceInfo.model : '';
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'remote-grading' });
    const socketRemote = io(process.env.REACT_APP_REMOTE_GRADER_URL, {
      withCredentials: false
    });
    socketRemote.on('connect', () => {
      console.log('CONNECTED!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
      var body = {
        id: 'photos.' + state.sessionInfo.uuid,
        store: state.sessionInfo.location
          ? state.sessionInfo.location.name
          : '',
        storeId: state.sessionInfo.location
          ? state.sessionInfo.location.store
          : '',
        phone: brand + ' ' + model,
        isWorking: true,
        ttl: '60000',
        isEmptyDrawer: false,
        misMatchPercentage: null,
        useApplication: false,
        isRegrade: false,
        photos: state.deviceInfo.photos
      };
      socketRemote.emit('/photos', body, (err, response, data) => {
        console.log(data);
        dispatch({ type: 'sessionInfo', payload: { evaluation: 1 } });
        dispatch({ type: 'gradingInfo', payload: data });
        console.log(response);
        // TRANSLATE DATA TO CONDITION
        // DISPATCH CONDITION
        if (
          data.phonePositionAnswer ===
          'Χμμ.. φαίνεται ότι η οθόνη σας είναι σβηστή. Τοποθετήστε τη συσκευή με ανοιχτή την οθόνη στο συρτάρι.'
        ) {
          navigate(process.env.PUBLIC_URL + '/turn-on-screen', {
            replace: true
          });
        } else if (
          data.phonePositionAnswer ===
            'Παρακαλώ τοποθετείστε το κινητό μέσα στο πράσινο πλαίσιο με την οθόνη να κοιτάει προς τα πάνω.' ||
          data.phonePositionAnswer ===
            'Παρακαλώ τοποθετείστε ένα κινητό την φορά.'
        ) {
          navigate(process.env.PUBLIC_URL + '/align-phone', { replace: true });
        } else if (data.isImageClear === false) {
          navigate(process.env.PUBLIC_URL + '/empty-drawer', { replace: true });
        } else if (
          data.phonePositionAnswer ===
          'Αυτή τη στιγμή δεν μπορούμε να αξιολογήσουμε συσκευές με σβηστή οθόνη. Θα χαρούμε να σας εξυπηρετήσουμε σύντομα ξανά!'
        ) {
          navigate(process.env.PUBLIC_URL + '/good-bye', { replace: true });
        } else if (
          data.phonePositionAnswer ===
          'Παρακαλώ αφαιρέστε τη θήκη και το προστατευτικό τζαμάκι οθόνης.'
        ) {
          navigate(process.env.PUBLIC_URL + '/remove-cover', { replace: true });
        } else if (data.hasMinorScratchDamage) {
          navigate(process.env.PUBLIC_URL + '/price-not-found', {
            replace: true
          });
        } else {
          if (
            data.hasWaterDamage |
            data.hasScreenDamage |
            data.hasGeneralDamage |
            data.hasButtonDamage |
            !state.sessionInfo.camera |
            ((state.webappInfo.digitizer === '0') |
              (state.webappInfo.sound === '0') |
              (state.webappInfo.biometrics === '0') |
              (state.webappInfo.front_camera === '0') |
              (state.webappInfo.back_camera === '0'))
          ) {
            dispatch({ type: 'sessionInfo', payload: { offer: 1 } });
            dispatch({ type: 'gradingInfo', payload: { condition: 'faulty' } });
            dispatch({
              type: 'priceInfo',
              payload: {
                price: Math.round(deviceInfo.price * deviceInfo.faulty),
                localPrice: Math.round(
                  deviceInfo.localPrices[state.flow.localPrices] *
                    deviceInfo.faulty
                ),
                basePrice: Math.round(deviceInfo.price * deviceInfo.faulty),
                localBasePrice: Math.round(
                  deviceInfo.localPrices[state.flow.localPrices] *
                    deviceInfo.faulty
                )
              }
            });
          } else if (data.hasDentsOnSidesDamage) {
            dispatch({ type: 'sessionInfo', payload: { offer: 1 } });
            dispatch({ type: 'gradingInfo', payload: { condition: 'poor' } });
            dispatch({
              type: 'priceInfo',
              payload: {
                price: Math.round(deviceInfo.price * deviceInfo.poor),
                localPrice: Math.round(
                  deviceInfo.localPrices[state.flow.localPrices] *
                    deviceInfo.poor
                ),
                basePrice: Math.round(deviceInfo.price * deviceInfo.poor),
                localBasePrice: Math.round(
                  deviceInfo.localPrices[state.flow.localPrices] *
                    deviceInfo.poor
                )
              }
            });
          } else if (data.isExceptional) {
            dispatch({ type: 'sessionInfo', payload: { offer: 1 } });
            dispatch({
              type: 'gradingInfo',
              payload: { condition: 'very_good' }
            });
            dispatch({
              type: 'priceInfo',
              payload: {
                price: Math.round(deviceInfo.price * deviceInfo.very_good),
                localPrice: Math.round(
                  deviceInfo.localPrices[state.flow.localPrices] *
                    deviceInfo.very_good
                ),
                basePrice: Math.round(deviceInfo.price * deviceInfo.very_good),
                localBasePrice: Math.round(
                  deviceInfo.localPrices[state.flow.localPrices] *
                    deviceInfo.very_good
                )
              }
            });
          } else {
            dispatch({ type: 'sessionInfo', payload: { offer: 1 } });
            dispatch({ type: 'gradingInfo', payload: { condition: 'good' } });
            dispatch({
              type: 'priceInfo',
              payload: {
                price: Math.round(deviceInfo.price * deviceInfo.good),
                localPrice: Math.round(
                  deviceInfo.localPrices[state.flow.localPrices] *
                    deviceInfo.good
                ),
                basePrice: Math.round(deviceInfo.price * deviceInfo.good),
                localBasePrice: Math.round(
                  deviceInfo.localPrices[state.flow.localPrices] *
                    deviceInfo.good
                )
              }
            });
          }
          socketRemote.close();
          navigate(process.env.PUBLIC_URL + '/offer', { replace: true });
        }
      });
    });
    socketRemote.on('connect_error', (err) => {
      navigate(process.env.PUBLIC_URL + '/identification-failed', {
        replace: true
      });
    });
    socketRemote.on('disconnect', function () {
      console.log('disconnected!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    });
    return () => {
      socketRemote.destroy();
    };
  }, []);

  return (
    <>
      <MainLayout noLogo exit>
        <Top></Top>
        <Center style={{ width: '100%', height: '100%' }}>
          <div className="flex-column center" style={{ width: '100%' }}>
            <MainImage img="Inspection/inspection.gif" />
            <MainTitle className="my-5 text-center" style={{ width: '47vw' }}>
              <Trans i18nKey="remote_grading.preparing_offer" />
            </MainTitle>
            <div className="d-flex py-5" style={{ width: '100%' }}>
              <div className="loader-line-identifying  loader-line-grading mx-4"></div>
            </div>
          </div>
        </Center>
      </MainLayout>
      <ExitPopup show={show} onHide={() => setShow(false)} />
    </>
  );
};

export default withTranslation()(Identification);
