import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainImage, MainCard, MainTitle, MainButton, InfoButton, EsimPopup } from '../../components';

const SimInstructions = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [hasSim, setHasSim] = useState(false);
    const [hasntSim, setHasntSim] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'sim-instructions' })
        dispatch({ type: "physical_sim", payload: false })
        dispatch({ type: "progress", payload: 'step1' })
    }, [])


    return (
        <>
            <MainLayout
                exit
                progress_bar
                nextButton={{
                    text: <Trans i18nKey="next_button" />,
                    to: hasSim ? process.env.PUBLIC_URL + state.flow.choose_sim.next_button.esim : process.env.PUBLIC_URL + state.flow.choose_sim.next_button.sim,
                    disabled: !hasSim && !hasntSim
                }}
                backButton={{ to: process.env.PUBLIC_URL + state.flow.choose_sim.back_button }}
            >
                <Top></Top>
                <Center>
                    <MainCard large className='justify-content-center align-items-center'>
                        <div className='d-flex flex-column align-items-center pb-5'>
                            <MainTitle style={{ width: '57vw' }} className="pb-5 text-center"><Trans i18nKey="choose_sim.title" /></MainTitle>
                            <InfoButton onClick={() => setShow(true)}><Trans i18nKey="choose_sim.info_button" /></InfoButton>
                            <div className='d-flex mt-5'>
                                <MainButton border={hasntSim ? ({ theme: { colors } }) => colors.primary : '#E6E6E6'} onClick={() => { setHasntSim(!hasntSim); setHasSim(false) }} className='flex-column mx-3' width='33.6vw' height='40vh'>
                                    <MainImage img='SimInstructions/sim.png' height='21vh' />
                                    <MainTitle className="mt-5 mb-4"><Trans i18nKey="choose_sim.sim_title" /></MainTitle>
                                    <div className='text-center fs24 lh32 fw400'><Trans i18nKey="choose_sim.sim_subtitle" /></div>
                                </MainButton>
                                <MainButton border={hasSim ? ({ theme: { colors } }) => colors.primary : '#E6E6E6'} onClick={() => { setHasSim(!hasSim); setHasntSim(false) }} className='flex-column mx-3' width='33.6vw' height='40vh'>
                                    <MainImage className='ms-5' img='SimInstructions/e_sim.png' height='21vh' />
                                    <MainTitle className="mt-5 mb-4"><Trans i18nKey="choose_sim.esim_title" /></MainTitle>
                                    <div className='text-center fs24 lh32 fw400'><Trans i18nKey="choose_sim.esim_subtitle" /></div>
                                </MainButton>
                            </div>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
            <EsimPopup show={show} onHide={() => setShow(false)} />
        </>
    );
}

export default withTranslation()(SimInstructions);