import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainVideo, MainCard, InfoButton, MainTitle, FmiPopup } from '../../components';

const FmiInfo = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [show, setShow] = useState(false);
    const ocr = state.flow.ocr_methods && Object.values(state.flow.ocr_methods).reduce((a,b)=> a||b, false);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'fmi-info' })
    }, [])


    return (
        <>
            <MainLayout
                exit
                progress_bar
                nextButton={{
                    text: <Trans i18nKey="next_button" />,
                    to: (state.check.fmi && state.check.sim) ? process.env.PUBLIC_URL + '/timer/sim-info' : (ocr ? process.env.PUBLIC_URL + '/timer/ocr' : process.env.PUBLIC_URL + '/timer/insert-complete'),
                }}
            >
                <Top></Top>
                <Center>
                    <MainCard className="align-items-center justify-content-center">
                        <MainVideo video='FmiInfo/fmi_info.mp4' />
                        <div>
                            <MainTitle style={{ width: '45vw' }} className='mb-5'><Trans i18nKey="fmi_info.title" /></MainTitle>
                            {/* <div className="my-5 fs20 lh32"><Trans i18nKey="fmi_info.subtitle" /></div> */}
                            <InfoButton onClick={() => setShow(true)}><Trans i18nKey="fmi_info.info_button" /></InfoButton>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
            <FmiPopup show={show} onHide={() => setShow(false)} />
        </>
    );
}

export default withTranslation()(FmiInfo);