import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainTitle, MainCard, MainImage, MainSubtitle } from '../../components';

const IdentificationFailed = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'identification-failed' })
    }, [])


    return (
        <>
            <MainLayout
                exit
                nextButton={{
                    text: <Trans i18nKey="identification_failed.next_button" />,
                    to: process.env.PUBLIC_URL + '/open-drawer',
                }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='align-items-center justify-content-center'>
                        <MainImage style={{ margin: '0 4vw' }} img='IdentificationFailed/identification_failed.png' height='32vh' />
                        <div style={{ width: '44vw' }}>
                            <MainTitle className="mb-3"><Trans i18nKey="identification_failed.title" /></MainTitle>
                            <MainSubtitle ><Trans i18nKey="identification_failed.subtitle" /></MainSubtitle>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default withTranslation()(IdentificationFailed);