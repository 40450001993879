import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Top,
  Center,
  BottomLeft
} from '../../layouts/MainLayout/MainLayout';
import {
  MainCard,
  ConditionTag,
  MainImage,
  FmiSimDeactivatePopup
} from '../../components';
import { navigate } from '@gatsbyjs/reach-router';

const Offer = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const brand = state.deviceInfo.brand ? state.deviceInfo.brand : '';
  const model = state.deviceInfo.model ? state.deviceInfo.model : '';
  // const color = state.deviceInfo.color ? state.deviceInfo.color : '';
  const capacity = state.deviceInfo.capacity ? state.deviceInfo.capacity : '';
  const price = state.priceInfo.localBasePrice
    ? state.priceInfo.localBasePrice
    : '';
  var finalPrice = 0;
  Object.keys(state.flow.payment_methods).forEach((method) => {
    if (
      state.flow.payment_methods[method] &&
      finalPrice < price * (1 - state.flow.price_modifiers[method])
    )
      finalPrice = Math.round(price * (1 - state.flow.price_modifiers[method]));
  });
  const [show, setShow] = useState(false);

  function popupType() {
    if (state.check.fmi && state.check.sim) return 'fmi_sim';
    else if (state.check.fmi) return 'fmi';
    else if (state.check.sim) return 'sim';
    else return 'error';
  }

  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'offer' });
    dispatch({ type: 'progress', payload: 'step6' });
  }, []);

  const onNextButton = () => {
    if (
      (state.check.fmi || state.check.sim) &&
      Object.keys(state.flow.payment_methods)
        .map(function (_) {
          return state.flow.payment_methods[_];
        })
        .filter((v) => v).length === 1
    ) {
      setShow(true);
    } else {
      setTimeout(() => {
        navigate(
          process.env.PUBLIC_URL + state.flow.offer.next_button.payment,
          { replace: true }
        );
      }, 100);
    }
  };

  return (
    <>
      <MainLayout
        progress_bar
        exit
        nextButton={{
          text: <Trans i18nKey="offer.next_button" />,
          to: '#',
          onClick: () => onNextButton()
        }}
      >
        <Top></Top>
        <Center>
          <MainCard className="d-flex align-items-center" large>
            <div style={{ width: '100%' }}>
              <div
                className="d-flex align-items-center mb-5"
                style={{ width: '100%' }}
              >
                <MainImage className="me-5" img="Offer/offer.png" height="" />
                <div style={{ width: '100%' }}>
                  <div className="fs30 fw600 lh48 mb-5">
                    {brand} {model}{' '}
                    <span className="fw400">
                      {' '}
                      {brand === 'Apple' && capacity}
                    </span>
                  </div>
                  <div>
                    <ConditionTag offer />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      borderBottom: '2px solid #E6E6E6',
                      margin: '50px 0 40px 0'
                    }}
                  ></div>
                  <div className="d-flex justify-content-between">
                    <div className="fs30 lh48">
                      {state.flow.payment_methods.voucher && (
                        <span>
                          <Trans i18nKey="offer.up_to" />{' '}
                        </span>
                      )}
                      <span
                        style={{ fontSize: '6.4rem' }}
                        className="fw600 lh28"
                      >
                        {state.flow.coin.front}
                        {finalPrice}
                        {state.flow.coin.back}
                        <span className="fs28 fw500">
                          {' '}
                          <Trans i18nKey="offer.payment_method" />
                        </span>
                      </span>
                      <div className="fs16 lh28 fontGray">
                        <Trans i18nKey="offer.prices_change" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  height: '10.5vh',
                  background: '#FBF7F4',
                  borderRadius: '0.8rem',
                  padding: '20px 20px 30px'
                }}
              >
                <div className="fs16 mb-3" style={{ color: '#B8B5B3' }}>
                  <Trans i18nKey="offer.trading" />
                </div>
                <div className="d-flex align-items-center fs16">
                  <div className="d-flex align-items-center">
                    <MainImage className="me-3" img="Offer/Tree.svg" />
                    <div>
                      <Trans i18nKey="offer.tree" />
                    </div>
                  </div>
                  <div
                    style={{ margin: '0 80px' }}
                    className="d-flex align-items-center"
                  >
                    <MainImage className="me-3" img="Offer/device.svg" />
                    <div>
                      <Trans i18nKey="offer.device" />
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <MainImage className="me-3" img="Offer/leaf.svg" />
                    <div>
                      <Trans i18nKey="offer.leaf" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MainCard>
        </Center>
        <BottomLeft>
          {/* <div style={{ position: 'relative', bottom: '7%', left: '4%' }}>
                        <img style={{ borderRadius: '1.6rem', maxWidth: '300px' }} src={"data:image/jpeg;base64," + state.deviceInfo.photos[0]} alt="live_feed_image" />
                    </div> */}
        </BottomLeft>
      </MainLayout>
      {popupType() !== 'error' && (
        <FmiSimDeactivatePopup
          show={show}
          onHide={() => setShow(false)}
          type={popupType()}
          nextButtonLink={state.flow.offer.next_button.fmi_sim}
        ></FmiSimDeactivatePopup>
      )}
    </>
  );
};

export default withTranslation()(Offer);
