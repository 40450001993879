import { Row } from 'react-bootstrap';
import { withTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { AppContext } from '../../utils/context';

const Button = styled.div`
  width: 100%;
  max-width: 32vw;
  min-height: 150px;
  border: ${(props) => props.border};
  border-radius: 8px;
  font-size: 38px;
  line-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 15px;
  margin: 8px;
`;

const border = {
  selected: '4px solid #1A1A1A',
  default: '2px solid #F2F2F2'
};

const RadioButton = ({ selected, onClick, image, text }) => {
  const appContext = useContext(AppContext);
  const { state } = appContext;
  return (
    <Button
      border={border[selected ? 'selected' : 'default']}
      onClick={onClick}
    >
      <Row style={{ width: '100%' }} className="align-items-center">
        <div
          className={`d-flex ps-4 ${
            image
              ? 'col-12 justify-content-end p-0'
              : 'col-3 align-items-center'
          }`}
        >
          <img
            src={`images/${state.flow.theme}/RadioButton/${
              selected ? 'selected' : 'unselected'
            }.svg`}
            alt="radio_icon"
            width="48"
          />
        </div>
        <div
          className={` align-items-center p-5 ${
            image ? 'col-12 justify-content-center' : 'col-9 align-items-center'
          }`}
        >
          {image && (
            <Row className="py-2 mx-5">
              <img
                src={`images/${state.flow.theme}/${image}`}
                alt="RadioImg"
                width="190"
              />
            </Row>
          )}
          <Row className="p-2">
            <p className="text-center mb-0">
              <Trans i18nKey={text} />
            </p>
          </Row>
        </div>
      </Row>
    </Button>
  );
};

export default withTranslation()(RadioButton);
