export const list = [
    "Apple iPhone XR",
    "Apple iPhone XS",
    "Apple iPhone XS Max",
    "Apple iPhone 11",
    "Apple iPhone 11 Pro",
    "Apple iPhone 11 Pro Max",
    "Apple iPhone SE (2020)",
    "Apple iPhone 12 Mini",
    "Apple iPhone 12",
    "Apple iPhone 12 Pro",
    "Apple iPhone 12 Pro Max",
    "Apple iPhone 13 mini",
    "Apple iPhone 13",
    "Apple iPhone 13 Pro",
    "Apple iPhone 13 Pro Max",
    "Apple iPhone SE (2022)",
    "Google	Pixel 3",
    "Google	Pixel 3a",
    "Google	Pixel 4",
    "Google	Pixel 4a",
    "Google	Pixel 5",
    "Google	Pixel 6",
    "Google	Pixel 6 Pro",
    "Huawei	P40",
    "Huawei	P40 Pro",
    "Huawei	Mate 40 Pro",
    "Motorola Razr 2019",
    "Samsung Galaxy Fold",
    "Samsung Galaxy Z Fold 2 5G",
    "Samsung Galaxy Z Flip",
    "Samsung Galaxy Z Flip 5G",
    "Samsung Galaxy Z Fold2 5G",
    "Samsung Galaxy S20",
    "Samsung Galaxy S21",
    "Samsung Galaxy S21+ 5G",
    "Samsung Galaxy S21 Ultra 5G",
    "Samsung Galaxy S22",
    "Samsung Galaxy S22+",
    "Samsung Galaxy S22 Ultra",
    "Samsung Galaxy Note 20 Ultra 5G",
    "Samsung Galaxy Note 20",
    "Samsung Galaxy Z Fold3 5G",
    "Nuu Mobile	X5",
    "Planet Computers Gemini PDA",
    "Rakuten Mobile	Rakuten Mini",
    "Oppo Find X3 Pro",
    "Oppo Reno 5 A",
    "Oppo Reno6 Pro 5G",
    "Oppo Find X5",
    "Oppo Find X5 Pro",
]