import { withTranslation, Trans } from "react-i18next";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../utils/context";
import {
  MainLayout,
  Top,
  Center,
  Bottom,
} from "../../layouts/MainLayout/MainLayout";
import { navigate } from "@gatsbyjs/reach-router";
import axios from "axios";
import { MainVideo } from "../../components";

const IdentificationSuccess = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;

  // useEffect(() => {
  //     dispatch({ type: "userJourney", payload: 'identification-success' })
  //     if (state.sessionInfo.flow === 'trade_in') {
  //         axios.get(`https://server.greenpanda.io/scripts/getWebassessment.php?uid=${state.sessionInfo.uuid}`, { 'Content-Type': 'application/x-www-form-urlencoded' })
  //             .then((res) => {
  //                 console.log(res.data);
  //                 dispatch({ type: "webappInfo", payload: res.data })
  //             })
  //             .catch(function (error) {
  //                 console.log(error);
  //                 dispatch({ type: "webappInfo", payload: {} })
  //             });
  //     }
  //     setTimeout(() => {
  //         navigate(process.env.PUBLIC_URL + "/identification", { replace: true })
  //     }, 3000);
  // }, [])
  useEffect(() => {
    dispatch({ type: "userJourney", payload: "identification-success" });
    const timeout = setTimeout(() => {
      navigate(process.env.PUBLIC_URL + "/identification", { replace: true });
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <MainLayout noLogo exit>
        <Top></Top>
        <Center>
          <div
            className="d-flex align-items-center flex-column"
            style={{ height: "65vh" }}
          >
            <MainVideo
              className="mb-5 pb-5"
              video="IdentificationSuccess/identification_success.mp4"
            />
            <div className="fs48 fw600 text-center">
              <Trans i18nKey="identification_success" /> 🎉
            </div>
          </div>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default withTranslation()(IdentificationSuccess);
