export const UploadData = (state) => {
    let currentDate = new Date()
    const timestamp = currentDate.getTime();

    var data = {
        "id": state.sessionInfo.uuid,
        "version": "2.0",
        // "flow": state.flow,
        "theme": state.flow.theme,
        "language": state.sessionInfo.language,
        "price": state.priceInfo.price,
        "localPrice": state.priceInfo.localPrice,
        "localCurrency": state.flow.localPrices,
        "coupon": state.deviceInfo.coupon,
        "localCouponPrice": state.deviceInfo.localCouponPrice,
        "voucherCode": state.userInformation.payment_value,
        "condition": state.gradingInfo.condition,
        "location": state.sessionInfo.location,
        "offer": {
            "IMEI": state.deviceInfo.imei,
            "brand": state.deviceInfo.brand,
            "model": state.deviceInfo.model,
            "price": state.priceInfo.price,
            "device": {
                "brand": state.deviceInfo.brand,
                "model": state.deviceInfo.model,
                "price": state.priceInfo.price
            },
            "operator": {
                "mcc": "",
                "mnc": "",
                "country": {
                    "ISO": "",
                    "name": ""
                },
                "network": {
                    "brand": "",
                    "status": "",
                    "operator": ""
                }
            },
            "modelName": state.deviceInfo.modelName,
            "isFirstAttempt": "",
            "isInvalidWebApiCalc": "",
            "invalidWebApiCalcMsg": "",
            "isValidPhonePosition": state.gradingInfo.isValidPhonePosition,
            "invalidPhonePositionAnswer": state.gradingInfo.invalidPhonePositionAnswer
        },
        "archived": "",
        "agcodData": "",
        "phoneData": {
            "id": {
                "IMEI": state.deviceInfo.imei,
                "IMSI": state.deviceInfo.imsi
            },
            "device": "",
            "isLocal": "",
            "operator": {
                "mcc": "",
                "mnc": "",
                "country": {
                    "ISO": "",
                    "name": ""
                },
                "network": {
                    "brand": "",//operator
                    "status": "",
                    "operator": ""
                }
            },
            "isPhoneAlreadyExceptional": ""
        },
        "storeData": {
            "host": "",
            "shop": "",
            "shuttle": "",
            "customer": "",
            "location": "",
            "product_no": "",
            "sa_server_ip": "",
            "sa_server_port": ""
        },
        "qrCodeData": "",
        "timestamps": {
            "start": state.sessionInfo.start,
            "finish": timestamp,
            "gsmNetworkUp": "",
            "phoneRegistered": ""
        },
        "webApiData": "",
        "bankAccount": {
            "iban": state.userInformation.iban,
            "cardNumber": state.userInformation.cardNumber,
            "name": state.userInformation.name,
            "email": state.userInformation.email,
            "tel": state.userInformation.phone,
            "mobile": state.userInformation.phone,
            "method": state.userInformation.payment_method,
            "sortCode": state.userInformation.cardNumber ??{
                "Iban": "",
                "BankCode": "",
                "BankName": "",
                "BranchCode": "",
                "CountryCode": "",
                "AccountNumber": ""
            },
            "receiptNo": "",
            "accountNumber": state.userInformation.cardNumber ?? ""
        },
        "recycleData": {
            "ber": state.deviceInfo.ber,
            "good": state.deviceInfo.good,
            "name": state.deviceInfo.name,
            "poor": state.deviceInfo.poor,
            "brand": state.deviceInfo.brand,
            "model": state.deviceInfo.model,
            "price": state.deviceInfo.price,
            "faulty": state.deviceInfo.faulty,
            "result": state.deviceInfo.result,
            "capacity": state.deviceInfo.capacity,
            "modelName": state.deviceInfo.modelName,
            "very_good": state.deviceInfo.vary_good,
            "localPrices": state.deviceInfo.localPrices,
            "manufacturer": state.deviceInfo.manufacturer,
            "valueIfLocked": "",
            "priceReductions": {
                "buttonDamage": "",
                "screenDamage": "",
                "generalDamage": "",
                "dentsOnSidesDamage": "",
                "minorScratchDamage": ""
            },
            "valueIfUnlocked": "",
            "valueIfExceptional": ""
        },
        "userGrading": {},
        "userJourney": state.userJourney,
        "voucherData": {
            "code": state.userInformation.payment_method === 'voucher' ? state.userInformation.payment_value : "",
            "used": "",
            "value": "",
            "message": "",
            "validDays": "",
            "voucherDate": "",
            "minPhoneValue": ""
        },
        "workingPhone": "",
        "giveToCharity": "",
        "paymentMethod": state.userInformation.payment_method,
        "phoneRecycled": "",
        "reductionData": {
            "max": "",
            "min": "",
            "graderValues": "",
            "customerValues": "",
            "maxGraderValue": "",
            "useMaxReduction": "",
            "maxCustomerValue": "",
            "maxReductionValues": "",
            "minReductionValues": "",
            "reductionDentsValues": "",
            "reductionButtonValues": "",
            "reductionScreenValues": "",
            "reductionGeneralValues": "",
            "useOnlyGraderReduction": "",
            "reductionMinorDentsValues": ""
        },
        "remoteGrading": {
            "useQrCode": false,
            "qrCodeData": null,
            "reductions": {
                "id": state.sessionInfo.uuid,
                "sent": state.gradingInfo.sent,
                "received": state.gradingInfo.received,
                "username": state.gradingInfo.username,
                "isImageClear": state.gradingInfo.isImageClear,
                "isExceptional": state.gradingInfo.isExceptional,
                "hasWaterDamage": state.gradingInfo.hasWaterDamage,
                "hasButtonDamage": state.gradingInfo.hasButtonDamage,
                "hasScreenDamage": state.gradingInfo.hasScreenDamage,
                "hasGeneralDamage": state.gradingInfo.hasGeneralDamage,
                "graderReviewStart": state.gradingInfo.graderReviewStart,
                "phonePositionAnswer": state.gradingInfo.phonePositionAnswer,
                "isValidPhonePosition": state.gradingInfo.isValidPhonePosition,
                "hasDentsOnSidesDamage": state.gradingInfo.hasDentsOnSidesDamage,
                "hasMinorScratchDamage": state.gradingInfo.hasMinorScratchDamage
            },
            "statusCode": "",
            "statusMessage": "",
            "selectedPhoneModel": ""
        },
        "userQuestions": {
            "optOutOfAdvertising": "",
            "termsAndConditionsAccepted": ""
        },
        "charityAmmount": "",
        "checkmendCheck": "",
        "useApplication": "",
        "phoneHDRecycled": "",
        "bankAccountCheck": "",
        "firstAttemptData": "",
        "calcByGraderModel": "",
        "remoteGradingVerification": "",
        "sessionInfo": state.sessionInfo,
        "deviceInfo": state.deviceInfo,
        "gradingInfo": state.gradingInfo,
        "priceInfo": state.priceInfo,
        "userInformation": state.userInformation
    }

    const body = {
        "uuid": state.sessionInfo.uuid,
        "type": "atm",
        "data": data,
        "brand": state.deviceInfo.brand,
        "model": state.deviceInfo.model,
        "store": state.sessionInfo.location.store,
        "imei": state.deviceInfo.imei,
        "state": "tx",
        "evaluation": state.sessionInfo.evaluation,
        "identification": state.sessionInfo.identification,
        "offer": state.sessionInfo.offer,
        "tx": state.sessionInfo.transaction,
        "price": state.priceInfo.price,
        "coupon": state.deviceInfo.coupon,
        "method": state.userInformation.payment_method
    }
    console.log('UPLOAD DATA',body)

    var axios = require('axios');
    var config = {
        method: 'post',
        url: 'https://session-service.greenpanda.io/transaction',
        headers: {
            'Content-Type': 'application/json'
        },
        data: body

    };
    axios(config)
        .then((res) => {
            console.log(res)
        })
}