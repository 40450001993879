import { withTranslation, Trans } from 'react-i18next';
import React from 'react';
import { Popup, ActionButton } from '../../components';

const InfoPopup = (props) => {
  return (
    <Popup show={props.show} onHide={props.onHide}>
      <div className="center flex-column">
        <div
          style={{ minHeight: '300px' }}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          {props.title && (
            <div className="fs30 fw700 text-center mx-5 py-5 px-5">
              <Trans i18nKey={props.title} />
            </div>
          )}
          {props.video && (
            <video autoPlay muted loop style={{ width: '100%' }}>
              <source src={props.video} type="video/mp4" />
            </video>
          )}
          {props.img && (
            <img
              style={{ maxHeight: '35vh' }}
              src={props.img}
              alt="popup_image"
            />
          )}
          {props.emoji && (
            <p
              style={{
                fontSize: '130px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {props.emoji}
            </p>
          )}
          <div className="fs38 fw600 text-center mx-5 pt-5 px-5">
            <Trans i18nKey={props.text} />
          </div>
          {props.subtitle && (
            <div className="fs30 fw400 text-center mx-5 px-5">
              <Trans i18nKey={props.subtitle} />
            </div>
          )}
        </div>
        <div className="mx-5 px-5">
          <div className="d-flex mt-5">
            {props.secondaryButtonText && (
              <ActionButton
                width="28vw"
                className="my-4 me-4"
                to={props.secondaryButtonTo}
                onClick={props.secondaryButtonOnClick}
                secondary
              >
                <Trans i18nKey={props.secondaryButtonText} />
              </ActionButton>
            )}
            <ActionButton
              width="28vw"
              className="my-4"
              to={props.primaryButtonTo}
              onClick={props.primaryButtonOnClick}
            >
              <Trans i18nKey={props.primaryButtonText} />
            </ActionButton>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default withTranslation()(InfoPopup);
