import { Trans } from 'react-i18next';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Top,
  Center,
  Bottom
} from '../../layouts/MainLayout/MainLayout';
import { MainCard, MainImage, MainTitle } from '../../components';

const Ocr = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;

  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'ocr' });
    dispatch({ type: 'progress', payload: 'step5' });
  }, [dispatch]);

  return (
    <>
      <MainLayout
        progress_bar
        exit
        nextButton={{
          text: <Trans i18nKey="next_button" />,
          to:
            state.userJourney.indexOf('device-open-drawer') !== -1
              ? process.env.PUBLIC_URL + '/timer/insert-complete'
              : state.userJourney.indexOf('open-drawer') === -1
              ? process.env.PUBLIC_URL + '/open-drawer'
              : process.env.PUBLIC_URL + '/timer/insert'
        }}
        backButton={{
          to:
            state.userJourney.indexOf('device-open-drawer') !== -1
              ? state.check.sim
                ? '#'
                : process.env.PUBLIC_URL + '/timer/fmi-info'
              : '#'
        }}
      >
        <Top></Top>
        <Center>
          <MainCard className="center flex-column pt-5">
            <MainTitle className="mb-5 text-center mx-5">
              <Trans i18nKey="ocr.Please, dial" />
            </MainTitle>
            {/* <MainVideo video="Ocr/ocr.gif" loop /> */}
            <MainImage style={{ marginTop: '2rem' }} img="Ocr/ocr.gif" />
            {/* <QRCode style={{marginRight: '5vw'}} size={150} value={'tel:%2A%2306%23'} /> */}
            <div className="mb-5">
              <MainTitle className="mb-3">
                <Trans i18nKey="ocr.and leave your screen on" />
              </MainTitle>
            </div>
          </MainCard>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default Ocr;
