import { withTranslation, Trans } from 'react-i18next';
import React, { useContext } from 'react';
import { AppContext } from '../../utils/context';
import { Popup, MainTitle, MainSubtitle, ActionButton } from '../../components';
import { navigate } from '@gatsbyjs/reach-router';

const ExitPopup = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const handleExit = () => {
    if (state.userJourney.indexOf('inspection') !== -1) {
      if (state.open_drawer) {
        setTimeout(() => {
          navigate(process.env.PUBLIC_URL + '/timer/remove-device', {
            replace: true
          });
        }, 100);
      } else {
        setTimeout(() => {
          navigate(process.env.PUBLIC_URL + '/exit-page', { replace: true });
        }, 100);
      }
    } else {
      setTimeout(() => {
        window.location.href = process.env.PUBLIC_URL + '/';
      }, 100);
    }
  };
  return (
    <Popup show={props.show} onHide={props.onHide}>
      <div className="text-center">
        <MainTitle className="m-5 px-5">
          <Trans i18nKey="exit_popup.title" />
        </MainTitle>
        <MainSubtitle className="my-5 pb-5">
          <Trans i18nKey="exit_popup.subtitle" />
        </MainSubtitle>
        <div className="d-flex justify-content-center">
          <div className="mx-4">
            <ActionButton
              onClick={() => handleExit()}
              to={'#'}
              secondary
              width="26vw"
            >
              <Trans i18nKey="exit_popup.yes" />
            </ActionButton>
          </div>
          <div className="mx-4">
            <ActionButton onClick={props.onHide} to="#" width="27vw">
              <Trans i18nKey="exit_popup.no" />
            </ActionButton>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default withTranslation()(ExitPopup);
