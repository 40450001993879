import { Modal } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';
import { Icons } from '..';
import { ReactComponent as ExitIcon } from './exit_icon.svg';

const Button = styled.div`
  width: 7.5rem;
  height: 7.5rem;
  background-color: ${({ theme: { colors } }) => colors.light_primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Popup = (props) => {
  return (
    <Modal
      className={props.className}
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body onContextMenu={(e) => e.preventDefault()}>
        <div className="d-flex justify-content-end">
          <Button onClick={props.onHide}>
            <span>
              <Icons
                width="3vw"
                clr={({ theme: { colors } }) => colors.primary}
              >
                <ExitIcon />
              </Icons>
            </span>
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center p-5">
          {props.children}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Popup;
