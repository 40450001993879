import { Trans } from 'react-i18next';
import React, { useContext, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainImage, MainCard, MainTitle } from '../../components';


const Screen = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    // const ocr = state.flow.ocr_methods && Object.values(state.flow.ocr_methods).reduce((a,b)=> a||b, false);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'screen' })
        dispatch({ type: "progress", payload: 'step4' })
    }, []);


    return (
        <>
            <MainLayout
                progress_bar
                exit
                nextButton={{
                    text: <Trans i18nKey="next_button" />,
                    to: process.env.PUBLIC_URL + state.flow.screen.next_button
                }}
                backButton={{ to: process.env.PUBLIC_URL + '/timer/accessories' }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='center'>
                        {/* <MainVideo style={{ marginRight: '5vw' }} video='Screen/screen.mp4' /> */}
                        <MainImage img="Screen/Screen.png" height="25vh" style={{ margin: '0 10vw 0 5vw' }}></MainImage>
                        <div>
                            <MainTitle style={{ width: '45vw' }} className='mb-5'><Trans i18nKey="screen" /></MainTitle>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default Screen;