import { withTranslation, Trans } from 'react-i18next';
import React from "react";
import { Popup } from '../../components';
import styled from "styled-components";

const Scrollbar = styled.div`
height: 65vh;
overflow-y: scroll;
font-size: 1.6rem;
font-weight: 500;
&::-webkit-scrollbar {
    width: 4px;
  }
   
  &::-webkit-scrollbar-track {
    background-color: #E6E6E6;
  }
   
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme: { colors } }) => colors.primary};
  }
`;

const PrivacyPolicy = (props) => {
    return (
        <Popup show={props.show} onHide={props.onHide}>
            <div style={{ marginTop: '-5vh' }} className='center flex-column px-5 mx-5'>
                <div className='fs34 fw600 mb-5'>Privacy Policy</div>
                <Scrollbar>
                    <div className='d-fle flex-column mx-5 px-5' style={{ width: '61vw' }}>
                        <div className='mb-5'>
                        "GREEN PANDA SINGLE-MEMBER PRIVATE COMPANY" respects and protects your privacy and your personal data. This Privacy Policy applies whenever you make use of the services offered to you through this evaluation machine of electronic device and the software incorporated thereon (hereinafter <b>“Green Panda ATM”</b>), regardless of whether you ultimately make a financial transaction and make use of the additional payment services provided or simply evaluate your electronic device. The protection of your Personal Data is governed by the legal framework <b>of the General Data Protection Regulation 2016/679 of the EU (hereinafter “GDPR”), as well as by the Greek law no. 4624/2019</b> "Personal Data Protection Authority, measures for the implementation of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of data” as currently in force.
                        </div>
                        <div className='mb-5'>
                        For the purposes of the applicable legislation, Controller shall mean the company under the name <b>“GREEN PANDA SINGLE-MEMBER PRIVATE COMPANY”</b>  having its registered office at 20 Charitos Street, P.C. 10675, Athens with GCR No 150229702000 <b>(hereinafter referred to as “our company”, “we”, “our”, “ours”)</b> and our purpose is to provide you with the most information possible regarding any form of processing of your personal data (e.g. collection, storage, etc.) as well as to inform you of your rights.
                        </div>
                        <div className='mb-5'>
                            <b>1. CATEGORIES OF DATA COLLECTED BY US</b><br />
                            A) <u>Your Identification Data</u>: Name, phone number, email address, collected directly from you either at the pre-contractual or at the contractual stage.<br />
                            In particular, your email is collected either at the stage you wish to receive just a financial offer of your electronic device, after evaluation of it by the embedded software in the Green Panda ATM, or at the stage of completion of a financial transaction. <br />
                            The rest of your information, i.e. your name and phone number, is collected only at the stage you wish to make a financial transaction, i.e. to pay to you the agreed price corresponding to the value of your electronic device as it resulted during the aforementioned evaluation, if you finally make it available to our company. In addition, it is collected if, instead of ultimately completing your financial transaction through the direct payment to you of the agreed price, you finally choose the issuance of a voucher which incorporates the amount of the financial offer corresponding to the value of the electronic device as it resulted during the aforementioned evaluation and may be used solely for the purchase of products from the stores of our partner enterprises.<br />
                            B) <u>Information about your device</u>:<br />
                            If you decide to evaluate your electronic device through the embedded software in the Green Panda ATM and using the “empty” SIM card provided by our company at this pre-contractual stage, after having removed your personal SIM card, we collect information about the type of electronic device you are using and the unique device ID number (IMEI number) solely for the purpose of assessing the commercial value of your device and creating a corresponding financial offer. Also if when receiving a financial offer you choose to issue a voucher instead of directly crediting to you the value of your device, the IMEI number is used by our company in order to confirm whether the voucher you have received with the financial offer for the value of your device was redeemed or expired or is still active for redemption. <br />
                            In addition, at this stage, we also collect image data only of the exterior and the screen of your device. This image is collected solely for the purpose of evaluating the commercial value of your device and its resulting costing (e.g. screen condition assessment, beatings, etc.).<br/>
                            If you evaluate your electronic device through the embedded software in the Green Panda ATM but using your personal SIM card, we collect in addition to IMEI, the International Mobile Subscriber Identity (IMSI number), always anonymized and encrypted, since a number of digits is collected which does not make the IMSI number identifiable to its owner.<br/>
                            To the extent that the above numbers (IMEI and IMSI), similar identifiers or similar data derived from them, or even the extracted image of the exterior of your device may lead to an identifiable natural person, so that they may be interpreted as or become personal data in accordance with the relevant applicable legislation, we undertake to treat such data as personal data in accordance with the terms of this Privacy Policy.<br/>
                            C) <u>Your financial data</u>: If you make a financial transaction and depending on the payment method you choose, we collect the following information:<br />
                            -In the case of bank transfer we collect directly from you in addition to the identification data under A and your International Bank Account Number (IBAN).<br/>
                            -In case of payment by a debit/credit card we collect directly from you in addition to the identification data under A and your unique debit/credit card number.<br/>
                            This information is collected directly from you for the purposes of the execution of the sales contract and the fulfilment of our obligations arising therefrom.<br/>
                            In the event that, notwithstanding the clear instructions that appear on the Green Panda ATM at both the pre-contractual and the contractual stage until the execution of a financial transaction, you do not proceed to the complete deletion of all your personal and non-personal data from the content of your electronic device and deliver it with full content (applications, photos, videos, etc.), our company neither assumes nor bears any responsibility towards you or any third party for any processing of such data. Any processing will be due to your own act or omission due to non-compliance with explicit instructions clearly displayed on the Green Panda Box. In any case, as soon as we become aware that the electronic device having been the subject of a financial transaction has been delivered with full content, including personal and non-personal data, such data will be automatically deleted securely.<br/>
                            By using Green Panda ATM, even if you do not make a financial transaction, you solemnly declare that you are an adult or minor over 15 years of age and you automatically accept that you are able to undertake and fulfil the obligations arising from any transaction through it. You also confirm that you fully understand and accept these terms and conditions. In the event that a minor under the age of 15 finally makes use of our services by falsely stating that they are over the age of 15, the persons who have parental responsibility of the minor are personally and unconditionally bound by these terms and conditions and fully acknowledge that they individually assume any damage that may be caused by the illegal and in violation of these terms use of our services.<br/>
                            <b>2. OUR PURPOSES FOR COLLECTING YOUR DATA</b><br />
                            - To take measures at your request at the pre-contractual stage, i.e. for a first evaluation of your electronic device and to provide you with a corresponding financial offer to be made available to our company and/or the issuance of a voucher that will incorporate the said financial value and may be used in accordance with the terms and conditions incorporated therein. The coupon will also be sent in electronic form to the email address (email) indicated by you.<br/>
                            - For the final and smooth preparation of the contract, namely: (a) either by paying you the agreed price (value of your electronic device) and corresponding sale of your electronic device to us, (b) or by issuing a relevant voucher by Green Panda ATM that will incorporate the economic value of your electronic device and may be redeemed by purchasing products from the stores of our partner enterprises and by selling your electronic device to us.<br/>
                            - To safeguard your legitimate interests and the protection of transactions in general, i.e. for the purpose of identifying you during the performance of the contract. Adequate identification serves to protect you from fraudulent behaviours of third parties in case of loss of your electronic device or your bank card.<br/>
                            - To comply with our legal obligations, if we receive a respective legal request for the transmission of such personal data to the competent police or prosecutorial/judicial or supervisory authorities and for the purpose of our compliance with the applicable legislation or for our compliance with the commercial or tax legislation, etc.<br/>
                            - To comply with our legitimate interests in accordance with Article 6 par. 1 (f) of the GDPR and provided that such collection does not constitute a breach of your individual rights and freedoms (i.e. processing is preceded by a relative weighting of interests). Specifically, the said data is collected in particular and primarily for the purpose of protecting transactions and preventing or prosecuting criminal or other offences (e.g. fraud, embezzlement, acceptance and placing on market the proceeds of crime, etc.).<br/>
                            - With your consent, we use your data to send you information (newsletter), for advertising purposes, direct marketing. We would like to inform you that by accepting this Privacy Policy you give us your explicit consent to use, collect and store your data for the above mentioned purpose (sending information material, advertising, direct marketing). In any case, you have the right to withdraw your consent by email at hello@pandas.io or simply by clicking “unsubscribe” in the corresponding emails you will receive.<br/>
                        </div>
                        <div className='mb-5'>
                            <b>3. PERMISSIONS OF THE SOFTWARE EMBEDDED INTO THE GREEN PANDA ATM (“Pandas App”)</b> <br />
                            If you decide to evaluate your electronic device, please place either your own SIM card, the empty SIM card provided by our company or any other form of SIM card we may provide you in the future (e.g. eSIM), activate the wireless Internet connection (Wi-Fi) from your electronic device, and scan the QR code that will appear on the screen of the Green Panda ATM, the Pandas app may require or request access to the following data of your electronic device in order to be installed and operate:
                            <ul>
                                <li>Location data (telecommunication network and/or GPS);</li>
                                <li>Access and use of the Camera;</li>
                                <li>Access to the microphone and handset;</li>
                                <li>Access to facial recognition and fingerprint sensors;</li>
                            </ul>
                        </div>
                        <div className='mb-5'>
                        All the above permissions are requested and take place solely for the purpose of checking the functionality of your electronic device for its final evaluation and related costing. The access to the above information is completely instantaneous and the data obtained during the evaluation is not stored by our company in any way.<br/>
                        </div>
                        <div className='mb-5'>
                            <b>4. TRANSMISSION OF YOUR PERSONAL DATA TO THIRD PARTIES</b><br />
                            Our company may transfer your data to our strictly necessary personnel, being bound by a relevant obligation of confidentiality as well as to our partner enterprises or third party service providers, who process your data as Processors on behalf and in accordance with our orders.<br/>
                            <b>Our personnel or our business partners</b> process your data solely for the purpose of providing the services offered by us. Our partner enterprises are always selected on the basis of, among other things, the high degree of protection they provide regarding the security of personal data. For example, our partner enterprises receive your identification data in order to be able to redeem the voucher you will show them if you have chosen to issue a voucher when using the Green Panda ATM that will incorporate the economic value of your electronic device.<br />
                            <b>Third party service providers</b> are companies that have been charged with the task of managing, maintaining and developing the technical features of the software incorporated in the Green Panda ATM and our servers in general, as well as with the task of hosting, managing and maintaining your data (hosting platforms/servers). Companies providing such services have been carefully selected and have experienced, competent and reliable staff and operate on the basis of all relevant requirements, specifications and rules set by the existing legislative framework.<br/>
                            The Processors have agreed and contractually committed to our Company to maintain a duty of confidentiality, to not share your data with third parties without our Company's permission, to process your data solely for the agreed processing purposes, to take appropriate technical and organizational security measures and generally to comply with the legal provisions on the protection of personal data in order to maintain a high level of data security.<br/>
                            Please contact us by sending an email to hello@pandas.io, if you want to receive a complete list of partner enterprises.<br/>
                            We reserve the right to share your personal data in cases where such disclosure is required by law or imposed by other competent supervisory, monitoring, independent, judicial, public and/or other authorities or is required for purposes prescribed by law, for which the individual's consent is not required (e.g. contribution to the prevention or investigation of fraud, to protect our legitimate interests and/or our property, etc.).<br/>
                            The processing of your personal data takes place within Greece and the European Union (EU). In case we cooperate with companies outside the EU, we will protect your data in the way described in this Privacy Policy and your data will only be processed upon instruction given by us and only in case there is an adequacy decision by the European Commission or if the appropriate safeguards (binding corporate rules, standard data protection clauses, approved code of conduct, etc.) are agreed which ensure a high level of security in relation to the processing of your personal data.<br/>
                            It is possible that our company processes personal data of third parties, which are transmitted directly to us by the customers/users of Green Panda ATM themselves, such as when the customer entering the electronic device for its pricing and sale is different from the owner of the electronic device or when the customer entering the financial data for making a payment is different from the holder of the bank account or bank card.<br/>
                            Under these circumstances, it is the responsibility of the customer/user appearing as the owner of the electronic device or the holder of the bank account/bank card during the execution of the financial transaction to have received the prior express/written consent from the owner/holder, before disclosing his/her personal data to our company (name, account details, etc.) and to inform him/her about this Privacy Policy, because the customer/user will be solely responsible for the transmission of the information and personal data of a third party who has not given his/her consent or in case this data is used in an illegal or inappropriate manner. Our company expressly disclaims any relevant liability that may arise from such unlawful or unfair use. In any case, any third party whose personal data is processed by our company under such circumstances has the same rights as those described in Section 7 of this Privacy Policy under the heading "Your Rights".<br/>
                        </div>
                        <div className='mb-5'>
                            <b>5. RETENTION PERIOD OF DATA KEPT BY US</b><br />
                            The retention period that we keep your personal data depends on the time required in order to provide you with the services you have selected (e.g. payment of money for the sale of your electronic device) and any relevant legitimate business purpose. Generally, when your personal data is no longer needed for legitimate business purposes or reasons, your personal data will be securely deleted and destroyed and/or can be anonymized in a shorter time to make them unidentifiable with your person.<br/>
                            In particular, when you choose to make a financial transaction and a final sale of your electronic device, we store your personal data for five (5) years from the execution of the contract between us.<br/>
                            Especially for the personal data we collect based on your consent, these are kept for as long as the relevant consent has been given or until its revocation if this occurs earlier.<br/>
                            Otherwise, and only exceptionally, we may retain your personal data for a longer period of time even after the termination of the relationship between us and the expiration of the deadlines for the exercise of the relevant rights that may arise therefrom (e.g. expiration of the coupon containing the financial offer, withdrawal from the contract, etc.), in order to comply with our legal interests as defined above (e.g. crime prevention or suppression) or with another legal obligation upon a relevant request from competent Public Authorities.<br/>
                        </div>
                        <div className='mb-5'>
                            <b>6. HOW WE PROTECT YOUR DATA/SECURITY MEASURES</b><br />
                            We introduce Appropriate Technical and Organizational Measures (hereafter "ATOM") of Security that are sufficient to minimize the risks of destruction, loss or alteration of data - even accidentally -, to prevent unauthorized access and dissemination of your personal information data, as well as to prevent illegal processing methods and types of processing that are not in accordance with the collection purposes stated in this Privacy Policy and, finally, that ensure the confidentiality, integrity and availability of your data.<br/>
                            However, we cannot guarantee that the ATOMs adopted to protect the electronic device that has been the subject of a financial transaction and the collection or any transfer of your personal data to a third party limit or exclude any risk of unauthorized access or dissemination of data, as the protection of your data depends on you as well: we recommend you to ensure that your electronic device is equipped with the appropriate software to protect the transmission of data online, both incoming and outgoing (such as updated antivirus systems, antispamming filters) and that the Internet service provider of your electronic device has also adopted appropriate security measures to protect the transmission of personal data over the Internet.<br/>
                        </div>
                        <div className='mb-5'>
                            <b>7. YOUR RIGHTS</b><br />
                            You have the right, at any time, to access your personal data, details and information that yourself have disclosed to our business (right of access).<br/>
                            You have the right to request the updating, correction and modification of your personal data (right of correction).<br/>
                            You have the right to request the deletion and permanent elimination of your personal data in whole or in part (right of deletion) (if e.g. the data are no longer necessary for the purposes for which they were collected, etc.).<br/>
                            You have the right to request the conversion into an anonymous form or blocking of your personal data used, including data that is considered unnecessary for the purposes for which the data were collected or subsequently processed (right to restrict processing).<br/>
                            You have the right to request that your personal data be transferred to another data controller in a secure manner (right to portability).<br/>
                            In any case, you have the right to object in whole or in part (right to object) to any further processing other than those expressly mentioned above.<br/>
                            You may freely exercise your rights at any time by sending a written request to our company at dpo@pandas.io and/or by sending a registered letter to 20 Charitos st., P.C. 10675, Athens, and we will respond immediately to your request.<br/>
                            We try to answer to all legitimate customer requests within one month of submitting them. Occasionally it may take longer than a month, taking into account the complexity of your request and the number of requests. In this case, we will notify you and inform you accordingly. However, in the event that your request is manifestly unfounded, excessive or repetitive, we can either impose a reasonable charge, which we will inform you of, or refuse to respond to your request.<br/>
                            In this case, we will notify you and inform you accordingly. However, in the event that your request is manifestly unfounded, excessive or repetitive, we can either impose a reasonable charge, which we will inform you of, or refuse to respond to your request.<br />
                            If you believe that your rights are being infringed in any way, you can also submit a complaint to the competent Supervisory Authority:<br/>
                            Hellenic Data Protection Authority<br />
                            Postal Address: 1-3 Kifissias Avenue, P.C. 115 23, Athens<br />
                            Call Center: +30-210 6475600<br />
                            Fax: +30-210 6475628<br />
                            E-mail: contact@dpa.gr
                        </div>
                        <div className='mb-5'>
                            <b>8. AMENDMENTS TO THE PRIVACY POLICY</b><br />
                            Our company may amend or update this Privacy Policy, in part or in full, even due to an update of the legislation governing the protection of your personal data, and protect your rights. Any such amendments and updates to the Privacy Policy will be posted on this application and communicated to you in this way so that they will be binding as soon as they are published there, and take effect from the moment of your next visit. We therefore invite you to visit this section every time you use Green Panda ΑΤΜ to read the latest and updated version of our Privacy Policy.<br/>
                        </div>
                        <div className='mb-5'>
                            <b>9. CONTACT US</b><br />
                            For any questions, clarifications or complaints in relation to this Privacy Policy, please contact:<br />
                            Data Controller:<br />
                            GREEN PANDA SINGLE-MEMBER PRIVATE COMPANY<br />
                            20 Charitos Street, P.C. 10675, Athens<br />
                            General Commercial Registry Number 150229702000<br />
                            hello@pandas.io<br />
                            Data Protection Officer:<br />
                            dpo@pandas.io
                        </div>
                    </div>
                </Scrollbar>
            </div>
        </Popup>
    );
}

export default withTranslation()(PrivacyPolicy);