import React from "react";
import { useContext } from "react";
import { AppContext } from "../../utils/context";

const MainImage = (props) => {

    const appContext = useContext(AppContext);
    const { state } = appContext;

    return (
        <div style={props.style} className={props.className} onClick={props.onClick}>
            <img src={`images/${state.flow.theme}/${props.img}`} alt={props.img} style={{ maxHeight: props.height, height: '100%' }} />
        </div>
    );
}

export default MainImage;