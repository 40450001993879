import { navigate } from "@gatsbyjs/reach-router";
import { withTranslation } from "react-i18next";
import { useEffect } from "react";

const Language = (props) => {
  const { lang, i18n } = props;

  useEffect(() => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    navigate(`/`);
  }, [i18n, lang]);

  return <></>;
};

export default withTranslation()(Language);
