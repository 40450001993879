import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainTitle, MainCard, MainImage } from '../../components';

const AlignPhone = (props) => {
  const appContext = useContext(AppContext);
  const { dispatch } = appContext;

  useEffect(() => {
    dispatch({ type: "userJourney", payload: 'clean-phone' })
  }, [dispatch])


  return (
    <>
      <MainLayout
        exit
        nextButton={{
          text: <Trans i18nKey="align_phone.next_button" />,
          to: process.env.PUBLIC_URL + '/open-drawer',
        }}
      >
        <Top></Top>
        <Center>
          <MainCard className='align-items-center justify-content-center'>
            <MainImage img='IdentificationFailed/clean_phone.svg' height='32vh' />
            <div style={{ width: '52vw' }}>
              <MainTitle className="mb-3"><Trans i18nKey="clean_phone.title" /></MainTitle>
            </div>
          </MainCard>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
}

export default withTranslation()(AlignPhone);