import { withTranslation, Trans } from "react-i18next";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../utils/context";
import {
  MainLayout,
  Top,
  Center,
  Bottom,
} from "../../layouts/MainLayout/MainLayout";
import { navigate } from "@gatsbyjs/reach-router";
import { MainVideo } from "../../components";

const Identification = (props) => {
  const { t, i18n } = props;
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const brand = state.deviceInfo.brand ? state.deviceInfo.brand : "";
  const model = state.deviceInfo.model ? state.deviceInfo.model : "";
  const color = state.deviceInfo.color ? state.deviceInfo.color : "";
  const capacity = state.deviceInfo.capacity ? state.deviceInfo.capacity : "";
  const imei = state.deviceInfo.imei ? state.deviceInfo.imei : "";

  useEffect(() => {
    dispatch({ type: "userJourney", payload: "identification" });
    let timeout = setTimeout(() => {
      navigate(process.env.PUBLIC_URL + "/remote-grading", { replace: true });
    }, 5000);
    // console.log(state.deviceInfo)
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <MainLayout noLogo exit>
        <Top></Top>
        <Center>
          <div
            className="d-flex justify-content-center mb-5 pb-5"
            style={{ height: "80vh" }}
          >
            <div className="text-center">
              <div className="fs30 mt-5 pt-5">
                <Trans i18nKey="identification" />
              </div>
              <MainVideo video="Identification/identification.mp4" />
              <div className="fs48 fw600">
                {brand} {model}
              </div>
              {brand === "Apple" && (
                <div className="fs30 lh48 mb-3">
                  {state.deviceInfo.capacity} / {color}
                </div>
              )}
              <div className="fs20 mt-5">IMEI: {imei}</div>
            </div>
          </div>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default withTranslation()(Identification);
