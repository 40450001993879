import { withTranslation, Trans } from 'react-i18next';
import React from "react";
import { Popup } from '../../components';
import styled from "styled-components";
import { list } from '../../components/EsimPopup/esimList';
import MainImage from '../MainImage/MainImage';

const Scrollbar = styled.div`
height: 65vh;
overflow-y: scroll;
font-size: 3rem;
font-weight: 500;
line-height: 2.8rem;
&::-webkit-scrollbar {
    width: 4px;
  }
   
  &::-webkit-scrollbar-track {
    background-color: #E6E6E6;
  }
   
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme: { colors } }) => colors.primary};
  }
`;

const EsimPopup = (props) => {
    return (
        <Popup show={props.show} onHide={props.onHide}>
            <div style={{ marginTop: '-5vh' }} className='center flex-column px-5 mx-5'>
                <div className='fs38 fw600 mb-5'><Trans i18nKey="esim_popup.title" /></div>
                <Scrollbar>
                    <div className='d-fle flex-column mx-5 px-5' style={{ width: '61vw' }}>
                        {list.map((item) => {
                            return (
                                <div key={item}>
                                    <div className='d-flex align-items-center my-5'>
                                        <MainImage img='SimInstructions/esim_list.png' />
                                        <div className='ms-3 '>{item}</div>
                                    </div>
                                    <div style={{ borderBottom: '2px solid #F2F2F2' }}></div>
                                </div>
                            );
                        })
                        }
                    </div>
                </Scrollbar>
            </div>
        </Popup>
    );
}

export default withTranslation()(EsimPopup);