import { Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Top,
  Center,
  Bottom
} from '../../layouts/MainLayout/MainLayout';
import { MainVideo, MainCard, MainTitle, Notification } from '../../components';

const InsertSim = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const [loading, setLoading] = useState(false);
  const ocr =
    state.flow.ocr_methods &&
    Object.values(state.flow.ocr_methods).reduce((a, b) => a || b, false);

  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'insert-sim' });
    dispatch({ type: 'physical_sim', payload: true });
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <MainLayout
        exit
        progress_bar
        nextButton={{
          text: <Trans i18nKey="next_button" />,
          to:
            state.userJourney.indexOf('device-open-drawer') !== -1
              ? ocr
                ? process.env.PUBLIC_URL + '/timer/ocr'
                : process.env.PUBLIC_URL + '/timer/insert-complete'
              : process.env.PUBLIC_URL + state.flow.insert_sim.next_button
        }}
        backButton={{
          to:
            state.userJourney.indexOf('device-open-drawer') !== -1
              ? process.env.PUBLIC_URL + '/timer/sim-info'
              : process.env.PUBLIC_URL + '/timer/get-sim'
        }}
      >
        <Top></Top>
        <Center>
          <MainCard className="center">
            <MainVideo
              style={{ marginRight: '3vw' }}
              video="InsertSim/insert_sim.mp4"
            />
            <div style={{ width: '39vw' }}>
              <MainTitle>
                <Trans i18nKey="insert_sim.title" />
              </MainTitle>
            </div>
            <Notification loading={loading} type="needle">
              <div>
                <Trans i18nKey="insert_sim.notification" />
              </div>
            </Notification>
          </MainCard>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default InsertSim;
