import { withTranslation, Trans } from "react-i18next";
import React from "react";
import styled from "styled-components";
import { Link } from "@gatsbyjs/reach-router";

const LinkStyled = styled(Link)`
  text-decoration: none;
`;
const Button = styled.div`
  background-color: #f7f7f7;
  color: #808080;
  font-size: 3.4rem;
  font-weight: 400;
  border-radius: 6.5rem;
`;

const ExitButton = (props) => {
  return (
    <LinkStyled
      replace={true}
      to={!props.disabled || !props.onClick ? props.to || "/" : "#"}
      onClick={props.onClick}
    >
      <Button className="px-5 py-2">
        <Trans i18nKey="exit" />
      </Button>
    </LinkStyled>
  );
};

export default withTranslation()(ExitButton);
