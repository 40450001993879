import React, { useReducer } from "react";
import { UploadData } from './../actions/uploadData';

export const AppContext = React.createContext({});
AppContext.displayName = "AppContext";

var newState = {};
//real
export const initialState = {
    atm_connection: {
        status: "Disconnected",
        error: false
    },
    idle: true,
    userJourney: [],
    progress: [],
    userInformation: {
        name:'',
        email:'',
        phone:'',
        payment_value: ''
    },
    checkbox: false,
    sessionInfo: {
        flow: '',
        language: '',
        uuid: '',
        camera: true,
        start: '',
        finish: '',
        evaluation: 0,
        identification: 0,
        offer: 0,
        transaction: 0,
        location: {
            id: '',
            name: ''
        }
    },
    deviceInfo: {
        coupon: 0,
        imei: "TBA"
    },
    gradingInfo: {
    },
    webappInfo: {},
    priceInfo: {},
    check: {
        fmi: false,
        sim: false,
    },
    rating: {},
    open_drawer: false,
    physical_sim: false
};


const reducer = (state, action) => {
    switch (action.type) {
        case "connection":
            return {
                ...state,
                atm_connection: action.payload
            };
        case "flow": 
            return {...state, flow: action.payload};
        case "init":
            return { ...initialState, ...action.payload };
        case "start":
            return { ...state, idle: false };
        case "userJourney":
            state.userJourney.push(action.payload);
            return state;
        case "percent":
            return {
                ...state,
                percent: action.payload
            };
        case "progress":
            state.progress.push(action.payload);
            return state;
        case "fmi_sim":
            return {
                ...state,
                check: {...state.check,...action.payload}
            };
        case "physical_sim":
            return {
                ...state,
                physical_sim: action.payload
            };
        case "open_drawer":
            return {
                ...state,
                open_drawer: action.payload
            };
        case "condition":
            return {
                ...state,
                condition: action.payload
            };
        case "price":
            return {
                ...state,
                price: action.payload
            };

        case "input":
            return {
                ...state,
                userInformation: {
                    ...state.userInformation,
                    ...action.payload
                }
            };
        case "checkbox":
            return {
                ...state,
                checkbox: {
                    ...state.checkbox,
                    ...action.payload
                }
            };
        case "rating":
            return {
                ...state,
                rating: action.payload
            };
        case "sessionInfo":
            // console.log("sessionInfo:", state.sessionInfo)
            return {
                ...state,
                sessionInfo: {
                    ...state.sessionInfo,
                    ...action.payload
                }
            };
        case "deviceInfo":
            newState = {
                ...state,
                deviceInfo: {
                    ...state.deviceInfo,
                    ...action.payload
                }
            };
            UploadData(newState);
            // console.log(newState.deviceInfo)
            return newState;
        case "webappInfo":
            console.log(state.webappInfo)
            return {
                ...state,
                webappInfo: {
                    ...state.webappInfo,
                    ...action.payload
                }
            };
        case "gradingInfo":
            newState = {
                ...state,
                gradingInfo: {
                    ...state.gradingInfo,
                    ...action.payload
                }
            };
            UploadData(newState);
            console.log('GRADING INFO',JSON.stringify(newState.gradingInfo));
            return newState;
        case "priceInfo":
            console.log('PRICE INFO',state.priceInfo)
            return {
                ...state,
                priceInfo: {
                    ...state.priceInfo,
                    ...action.payload
                }
            };
        case "SET_DEVICES":
            return {
                ...state,
                devices: action.payload
            };
        case "SET_BRANDS":
            return {
                ...state,
                brands: action.payload
            };
        case "DATA":
            // console.log(state.data)
            return {
                ...state,
                data: { "id": state.sessionInfo.uuid }
            };
        default:
            return state;
    }
};


const AppContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;