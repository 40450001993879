// TODO: refactor with remote configuration option and use of UTC timezones.
import { utcToZonedTime } from 'date-fns-tz';

const timezone = 'Europe/Athens';
export const businessHours = [
  {
    daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    openHours: [{ openHour: 9, closeHour: 21 }]
  },
  {
    daysOfWeek: ['Saturday'],
    openHours: [{ openHour: 11, closeHour: 19 }]
  }
];

export function isOpen() {
  const date = new Date();
  const localDate = utcToZonedTime(date.toISOString(), timezone);
  const dayOfWeek = localDate.toLocaleDateString('en-GR', { weekday: 'long' });
  const hours = businessHours.find((h) => h.daysOfWeek.includes(dayOfWeek));
  if (!hours) return false;
  for (const openHours of hours.openHours) {
    const openHour = openHours.openHour;
    const closeHour = openHours.closeHour;
    const currentHour = localDate.getHours();
    if (currentHour >= openHour && currentHour < closeHour) {
      return true;
    }
  }
  return false;
}

