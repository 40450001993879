import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Top,
  Center,
  Bottom
} from '../../layouts/MainLayout/MainLayout';
import {
  MainImage,
  MainCard,
  MainTitle,
  PaymentButton,
  FmiSimDeactivatePopup
} from '../../components';
import styled from 'styled-components';
import { navigate } from '@gatsbyjs/reach-router';

const Scrollbar = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const PaymentOptions = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const [show, setShow] = useState(false);
  const price = state.priceInfo.localBasePrice
    ? state.priceInfo.localBasePrice
    : '';

  function popupType() {
    if (state.check.fmi && state.check.sim) return 'fmi_sim';
    else if (state.check.fmi) return 'fmi';
    else if (state.check.sim) return 'sim';
    else return 'error';
  }

  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'payment-options' });
    if (
      Object.keys(state.flow.payment_methods)
        .map(function (_) {
          return state.flow.payment_methods[_];
        })
        .filter((v) => v).length === 1
    ) {
      let method = Object.entries(state.flow.payment_methods).filter(
        (entry) => entry[1]
      )[0][0];
      dispatch({
        type: 'priceInfo',
        payload: {
          price: Math.round(
            state.priceInfo.basePrice * (1 - state.flow.price_modifiers[method])
          ),
          localPrice: Math.round(
            state.priceInfo.localBasePrice *
              (1 - state.flow.price_modifiers[method])
          )
        }
      });
      navigate(process.env.PUBLIC_URL + `/${method.replace('_', '-')}`);
    }
    console.log(
      Object.entries(state.flow.payment_methods).filter((entry) => entry[1])
    );
  }, []);

  return (
    <>
      <MainLayout exit progress_bar>
        <Top></Top>
        <Center>
          <MainCard
            height="100%"
            className="flex-column align-items-center mt-5"
          >
            <div
              style={{ width: '85%' }}
              className="d-flex justify-content-between my-5"
            >
              <MainImage
                onClick={() =>
                  navigate(process.env.PUBLIC_URL + '/offer', { replace: true })
                }
                className="my-5 ms-3"
                img={`PaymentOptions/back_button.svg`}
                height="5.5vh"
              />
              <MainTitle className="my-5">
                <Trans i18nKey="payment_options.title" />
              </MainTitle>
              <div></div>
            </div>
            <Scrollbar>
              <div style={{ width: '85%' }}>
                {Object.entries(state.flow.payment_methods)
                  .filter((entry) => entry[1])
                  .map((entry) => {
                    return (
                      <PaymentButton
                        key={entry[0]}
                        onClick={() => {
                          dispatch({
                            type: 'priceInfo',
                            payload: {
                              price: Math.round(
                                state.priceInfo.basePrice *
                                  (1 - state.flow.price_modifiers[entry[0]])
                              ),
                              localPrice: Math.round(
                                state.priceInfo.localBasePrice *
                                  (1 - state.flow.price_modifiers[entry[0]])
                              )
                            }
                          });
                          if (
                            (state.check.fmi ||
                              state.check.sim) &&
                            Object.keys(state.flow.payment_methods)
                              .map(function (_) {
                                return state.flow.payment_methods[_];
                              })
                              .filter((v) => v).length !== 1
                          ) {
                            setShow(true);
                          } else {
                            navigate(
                              process.env.PUBLIC_URL +
                                `/${entry[0].replace('_', '-')}`,
                              { replace: true }
                            );
                          }
                        }}
                        img={entry[0]}
                        title={
                          <Trans i18nKey={`payment_options.${entry[0]}`} />
                        }
                        // subtitle={`${coupon}€ Promo code included`}
                        finalPrice={Math.round(
                          price * (1 - state.flow.price_modifiers[entry[0]])
                        )}
                      />
                    );
                  })}
              </div>
            </Scrollbar>
          </MainCard>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
      <FmiSimDeactivatePopup
        show={show}
        onHide={() => setShow(false)}
        type={popupType()}
        nextButtonLink={state.flow.offer.next_button.fmi_sim}
      ></FmiSimDeactivatePopup>
    </>
  );
};

export default withTranslation()(PaymentOptions);
