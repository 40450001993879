import { Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Top,
  Center,
  Bottom
} from '../../layouts/MainLayout/MainLayout';
import { MainCard, MainTitle, MainVideo, Notification } from '../../components';

const Accessories = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'accessories' });
    dispatch({ type: 'progress', payload: 'step1' });
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <MainLayout
        progress_bar
        exit
        nextButton={{
          text: <Trans i18nKey="next_button" />,
          to: process.env.PUBLIC_URL + '/timer/factory-settings'
        }}
        backButton={{
          to:
            state.flow.accessories.back_button === '/timer/sim-instructions'
              ? state.physical_sim
                ? process.env.PUBLIC_URL + '/timer/insert-sim'
                : process.env.PUBLIC_URL + '/sim-qr'
              : process.env.PUBLIC_URL + '/'
        }}
      >
        <Top></Top>
        <Center>
          <MainCard className="center">
            <MainVideo
              style={{ marginRight: '5vw' }}
              video="Accessories/accessories.mp4"
            />
            <div>
              <MainTitle style={{ width: '45vw' }} className="mb-5">
                <Trans i18nKey="accessories.title" />
              </MainTitle>
            </div>
            <Notification loading={loading} type="info">
              <div>
                <Trans i18nKey="accessories.notification" />
              </div>
            </Notification>
          </MainCard>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default Accessories;
