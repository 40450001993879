import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MainImage } from '..';
import { Trans } from 'react-i18next';
const jsondata = require('../../translations/english.json');

const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  // background: #F2F2F2;
  // border-radius: 16px;
  font-size: 2.8rem !important;
  line-height: 7.2rem;
  font-weight: 500;
  margin: 0.7rem;
  // width: 42vw;
  max-width: 83vw;
`;

const InstructionCard = (props) => {
  const [instructions, setInstructions] = useState([]);
  useEffect(() => {
    var keys = [];
    for (let key in jsondata['factory_reset_popup'][props.title]) {
      if (key !== 'title') keys.push(key);
    }
    setInstructions(keys);
  }, []);

  return (
    <Box className="center pt-5">
      <MainImage
        style={{ marginRight: '2vw' }}
        height="24.4rem"
        img={`InstructionCard/${props.title}.svg`}
      />
      <div style={{ height: '100%' }}>
        <ol>
          {instructions.map((data, index) => {
            return (
              <div key={index.toString()}>
                <li>
                  <Trans
                    i18nKey={`factory_reset_popup.${props.title}.${data}`}
                  />
                </li>
              </div>
            );
          })}
        </ol>
      </div>
    </Box>
  );
};

export default InstructionCard;
