import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainCard, Input, PromoCode } from '../../components';
import axios from 'axios';

const Iban = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [iban, setIban] = useState();

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'iban' })
    }, [])

    const header = { 'Content-Type': 'Access-Control-Allow-Origin' }
    useEffect(() => {
        if (state.userInformation.payment_value) {
            axios.post(`https://payment-service.greenpanda.io/validateIban`, { 'iban': state.userInformation.payment_value, }, header)
                .then(function (response) {
                    console.log(response);
                    setIban(true)
                    dispatch({
                        type: "input",
                        payload: {
                            payment_method: "iban",
                            iban: state.userInformation.payment_value
                        }
                    })
                })
                .catch(function (error) {
                    setIban(false)
                    console.log(error);
                });
        }
    }, [state.userInformation.payment_value]);


    return (
        <>
            <MainLayout
                exit
                progress_bar
                nextButton={{
                    text: <Trans i18nKey="iban.next_button" />,
                    to: process.env.PUBLIC_URL + '/user-information',
                    disabled: !iban
                }}
                backButton={{ to: process.env.PUBLIC_URL + '/payment-options' }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='flex-column center' large>
                        <div className='fs30 fw600 text-center mb-5 pb-3'><Trans i18nKey="iban.title" /></div>
                        <div>
                            <Input label='IBAN' field='payment_value' placeholder='Enter your IBAN' />
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default withTranslation()(Iban);