import { withTranslation, Trans } from 'react-i18next';
import SideLayoutPopup from '../Popup/variations/SideLayoutPopup';

const FmiSimDeactivatePopup = (props) => {
  const popupImage = {
    fmi_sim: 'Offer/fmi_sim.png',
    fmi: 'Offer/fmi.png',
    sim: 'Offer/sim.png'
  };

  const popupTitle = {
    fmi_sim: 'check_popup.title1',
    fmi: 'check_popup.title2',
    sim: 'check_popup.title2'
  };

  function popupSubtitle(type) {
    if (type === 'fmi_sim') {
      return (
        <div>
          1. <Trans i18nKey="check_popup.fmi" />
          <br />
          2. <Trans i18nKey="check_popup.sim" />
        </div>
      );
    } else if (type === 'fmi') {
      return <Trans i18nKey="check_popup.fmi" />;
    } else if (type === 'sim') {
      return <Trans i18nKey="check_popup.sim" />;
    } else {
      return null;
    }
  }
  return (
    <SideLayoutPopup
      show={props.show}
      onHide={props.onHide}
      nextButtonLink={props.nextButtonLink}
      image={popupImage[props.type]}
      title={<Trans i18nKey={popupTitle[props.type]} />}
      subtitle={popupSubtitle(props.type)}
    ></SideLayoutPopup>
  );
};

export default withTranslation()(FmiSimDeactivatePopup);
