import { withTranslation, Trans } from "react-i18next";
import React, { useContext } from "react";
import { AppContext } from "../../utils/context";
import styled from "styled-components";
import LikeNewIcon from "./images/like_new.svg";
import GoodIcon from "./images/good.svg";
import PoorIcon from "./images/poor.svg";
import FaultyIcon from "./images/faulty.svg";
import { navigate } from "@gatsbyjs/reach-router";

const Tag = styled.div`
  font-size: ${(props) => (props.offer ? "2rem" : "3rem")};
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 10px 30px 10px 10px;
  border-radius: 0.8rem;
  width: 38vw;
`;

const ConditionTag = (props) => {
  const { t, i18n } = props;
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const condition = state.gradingInfo.condition
    ? state.gradingInfo.condition
    : "";

  return (
    <Tag
      offer={props.offer}
      style={{
        background: props.offer
          ? condition === "good"
            ? "#EEFEF2"
            : condition === "poor"
            ? "#FFF9E5"
            : condition === "faulty"
            ? "#FFEDEE"
            : "#FAF4FF"
          : "#FFFFFF",
      }}
    >
      <div style={{ width: "100%" }} className="d-flex justify-content-between">
        {condition === "good" ? (
          <div className="d-flex align-items-center">
            <img
              className="me-3"
              src={GoodIcon}
              alt="very_good_filled_icon"
              width="35"
            />
            {props.offer ? (
              <div>
                <Trans i18nKey="conditionTag.offer good" />
              </div>
            ) : (
              <div>
                <Trans i18nKey="conditionTag.report good" />
              </div>
            )}
          </div>
        ) : condition === "poor" ? (
          <div className="d-flex align-items-center">
            <img
              className="me-3"
              src={PoorIcon}
              alt="very_good_filled_icon"
              width="35"
            />
            {props.offer ? (
              <div>
                <Trans i18nKey="conditionTag.offer poor" />
              </div>
            ) : (
              <div>
                <Trans i18nKey="conditionTag.report poor" />
              </div>
            )}
          </div>
        ) : condition === "faulty" ? (
          <div className="d-flex align-items-center">
            <img
              className="me-3"
              src={FaultyIcon}
              alt="very_good_filled_icon"
              width="35"
            />
            {props.offer ? (
              <div>
                <Trans i18nKey="conditionTag.offer faulty" />
              </div>
            ) : (
              <div>
                <Trans i18nKey="conditionTag.report faulty" />
              </div>
            )}
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <img
              className="me-3"
              src={LikeNewIcon}
              alt="very_good_filled_icon"
              width="35"
            />
            {props.offer ? (
              <div>
                <Trans i18nKey="conditionTag.offer like_new" />
              </div>
            ) : (
              <div>
                <Trans i18nKey="conditionTag.report like_new" />
              </div>
            )}
          </div>
        )}
        {props.offer ? (
          <div
            onClick={() =>
              navigate(process.env.PUBLIC_URL + "/detailed-report", {
                replace: true,
              })
            }
            style={{
              color:
                condition === "good"
                  ? "#0FBD3B"
                  : condition === "poor"
                  ? "#CC9900"
                  : condition === "faulty"
                  ? "#FF4B55"
                  : "#BB66FF",
            }}
          >
            <Trans i18nKey="conditionTag.details" />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </Tag>
  );
};

export default withTranslation()(ConditionTag);
