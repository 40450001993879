import { Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Top,
  Center,
  Bottom
} from '../../layouts/MainLayout/MainLayout';
import { MainTitle, RadioButton } from '../../components';

const Accessories = (props) => {
  const appContext = useContext(AppContext);
  const { dispatch } = appContext;

  const [factorySettings, setFactorySettings] = useState(null);
  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'factory-settings' });
    dispatch({ type: 'progress', payload: 'step2' });
  }, [dispatch]);

  return (
    <>
      <MainLayout
        progress_bar
        exit
        nextButton={{
          text: <Trans i18nKey="next_button" />,
          to:
            factorySettings !== null
              ? factorySettings
                ? process.env.PUBLIC_URL + '/timer/operating-system'
                : process.env.PUBLIC_URL + '/timer/ocr'
              : '#',
          disabled: factorySettings === null
        }}
        backButton={{
          to: process.env.PUBLIC_URL + '/timer/accessories'
        }}
      >
        <Top></Top>
        <Center>
          <div>
            <MainTitle className="mb-5 pb-5 text-center">
              <Trans i18nKey="factory_settings.Is your device reset to factory settings?" />
            </MainTitle>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <RadioButton
              text="Yes"
              selected={factorySettings}
              onClick={() => {
                setFactorySettings(true);
              }}
            />
            <RadioButton
              text="No"
              selected={factorySettings === false}
              onClick={() => {
                setFactorySettings(false);
              }}
            />
          </div>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default Accessories;
