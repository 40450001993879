import React from 'react';
import styled from 'styled-components';
import InfoIcon from './info.svg';
import NeedleIcon from './needle.svg';
import ErrorIcon from './error.svg';
import WarningIcon from './warning.svg';

const Popup = styled.div`
  position: absolute;
  top: -100%;
  left: 50%;
  transition: all 1s;
  background: none;
  border-radius: 16px;
  transform: translate(-50%, -50%);
  width: fit-content;
  display: ${(props) => props.display || 'flex'};
`;

const Inner = styled.div`
  height: 8vh;
  max-height: 90px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  background: ${(props) => props.background};
  border-radius: 16px;
  font-size: 2rem;
  font-weight: 400;
  line-height: 3rem;
`;

const Notification = ({ loading, display, type, children }) => {
  function background(type) {
    switch (type) {
      case 'info':
        return '#E5FFEC';
      case 'warning':
        return '#FFB2291A';
      case 'error':
        return '#FFCCCF';
      case 'needle':
        return '#E5FFEC';
      default:
        return '#E5FFEC';
    }
  }

  function image(type) {
    switch (type) {
      case 'info':
        return InfoIcon;
      case 'warning':
        return WarningIcon;
      case 'error':
        return ErrorIcon;
      case 'needle':
        return NeedleIcon;
      default:
        return InfoIcon;
    }
  }

  return (
    <Popup style={loading ? {} : { top: '-3%' }} display={display}>
      <Inner background={background(type)}>
        {<img className="me-5" src={image(type)} alt="icon" />}
        {children}
      </Inner>
    </Popup>
  );
};

export default Notification;
