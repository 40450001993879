import { withTranslation } from 'react-i18next';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../utils/context';
import { Drawer } from '../../components';
import { navigate } from '@gatsbyjs/reach-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as sdk from '../../actions/sdk';

const OpenDrawer = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const notify = () =>
    toast(<div className="fs24 text-center">motion open</div>);
  const ocr =
    state.flow.ocr_methods &&
    Object.values(state.flow.ocr_methods).reduce((a, b) => a || b, false);

  useEffect(() => {
    let isApiSubscribed = true;
    dispatch({ type: 'userJourney', payload: 'open-drawer' });
    dispatch({ type: 'open_drawer', payload: true });
    console.log('OPEN DRAWER');
    sdk
      .openDrawer()
      .then((response) => {
        if (isApiSubscribed) {
          console.log(JSON.stringify(response.data));
          if (ocr && state.userJourney.indexOf('inspection') !== -1) {
            navigate(process.env.PUBLIC_URL + '/timer/ocr', {
              replace: true
            });
          } else {
            navigate(process.env.PUBLIC_URL + '/timer/insert', {
              replace: true
            });
          }
        }
      })
      .catch((e) => {
        if (process.env.REACT_APP_DEBUG === '1') notify();
        if (e.response?.data?.kioskState === 'opened' && isApiSubscribed) {
          if (ocr && state.userJourney.indexOf('inspection') !== -1) {
            navigate(process.env.PUBLIC_URL + '/timer/ocr', {
              replace: true
            });
          } else {
            navigate(process.env.PUBLIC_URL + '/timer/insert', {
              replace: true
            });
          }
        }
        // TODO: catch hardware errors
        console.log(JSON.stringify(e));
      });
    return () => {
      isApiSubscribed = false;
    };
  }, []);

  return (
    <>
      <Drawer open />
    </>
  );
};

export default withTranslation()(OpenDrawer);
