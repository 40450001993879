import { withTranslation, Trans } from 'react-i18next';
import QuizButton from './Button';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { questions } from './questions.json';
import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../utils/context';
import { InfoPopup } from '..';
const quizLoadingTimeout = 3000;

const Container = styled.div`
  background-color: black;
  width: 100%;
  height: 88vh;
`;
const LoadingPopup = styled.div`
  height: 75%;
  width: 75%;
  background: #333333;
  color: white;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Background = styled.div`
  background-image: url(images/greenpanda/Quiz/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  background: #000000;
  color: #ffffff;
`;
const StatusBar = styled.div`
  background: #00000080;
  color: #ffffff;
`;
const Result = styled.div`
  border: 4px solid #ffffff;
  border-radius: 16px;
  font-size: 30px;
  padding: 65px;
  text-align: center;
  margin: 73px 70px 35px;
`;
const HexagonBorder = styled.div`
  position: absolute;
  background: white;
  width: 262px;
  height: 79px;
  clip-path: polygon(17% 0, 83% 0, 100% 50%, 83% 100%, 17% 100%, 0% 50%);
`;
const HexagonInner = styled.div`
  position: absolute;
  top: 4px;
  left: 6px;
  background: #aa61f2;
  width: 250px;
  height: 70px;
  clip-path: polygon(16% 0, 84% 0, 100% 50%, 84% 100%, 16% 100%, 0% 50%);
  &::before {
    height: 65px;
    content: '${(props) => props.text}';
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 51px;
    font-weight: 600;
  }
`;
const ExitButton = styled.div`
  background: rgba(247, 247, 247, 0.1);
  border-radius: 65px;
  color: #808080;
  padding: 13px 31px;
  display: flex;
  width: 117px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 25px;
  right: 30px;
`;
const PlayAgain = styled.div`
  border: 1px solid #bbffcc;
  border-radius: 65px;
  color: #bbffcc;
  padding: 15px 25px;
  display: flex;
  width: 270px;
  font-size: 34px;
  display: flex;
  justify-content: center;
`;

const Quiz = (props) => {
  const appContext = useContext(AppContext);
  const { state } = appContext;
  const [question, setQuestion] = useState(0);
  const [userAnswer, setUserAnswer] = useState(null);
  const [popupShow, setPopupShow] = useState(false);
  const [popupType, setPopupType] = useState();
  const [score, setScore] = useState(0);
  const [timer, setTimer] = useState(20);
  const [loading, setLoading] = useState(true);

  const timePopupProps = {
    text: 'quiz.popup.Need some extra time to answer this question?',
    image: `images/${state.flow.theme}/Quiz/Hourglass.svg`,
    primaryButtonText: 'quiz.popup.No, skip question',
    primaryButtonOnClick: nextQuestion,
    secondaryButtonText: 'quiz.popup.Yes please',
    secondaryButtonOnClick: () => {
      setTimer(20);
      setPopupShow(false);
    },
    onHide: () => {
      setTimer(10);
      setPopupShow(false);
    }
  };
  const exitPopupProps = {
    text: 'quiz.popup.Are you sure you want to exit quiz?',
    onHide: () => {
      setPopupShow(false);
    },
    primaryButtonText: 'No',
    primaryButtonOnClick: () => {
      setPopupShow(false);
    },
    secondaryButtonText: 'Yes',
    secondaryButtonOnClick: props.to
      ? null
      : () => {
          props.onHide();
        },
    secondaryButtonTo: props.to
  };
  const successPopups = [
    { text: 'quiz.popup.Correct!', emoji: '🙌' },
    { text: 'quiz.popup.Great!', emoji: '🤩' },
    { text: 'quiz.popup.Super!', emoji: '🥳' },
    { text: 'quiz.popup.Yey!!', emoji: '🎉' },
    { text: 'quiz.popup.Top!', emoji: '👍' },
    { text: 'quiz.popup.All right!', emoji: '⭐' }
  ];
  const failurePopups = [{ text: 'quiz.popup.Oh no!', emoji: '😐' }];

  const nextQuestionPopupProps = {
    primaryButtonText: 'quiz.popup.Next Question',
    primaryButtonOnClick: nextQuestion,
    onHide: nextQuestion
  };
  //quiz loading
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, quizLoadingTimeout);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  //timer
  useEffect(() => {
    let secondsCounterInterval;
    if (question < questions.length) {
      secondsCounterInterval = setInterval(() => {
        if (popupShow) return;
        if (timer > 0) {
          setTimer(timer - 1);
        } else {
          setPopupShow(true);
          setPopupType('timePopup');
        }
      }, 1000);
    }
    return () => {
      clearInterval(secondsCounterInterval);
    };
  });

  function nextQuestion() {
    setPopupShow(false);
    setQuestion(question + 1);
    setUserAnswer(null);
    setTimer(20);
  }

  useEffect(() => {
    let timeout;
    if (userAnswer !== null) {
      timeout = setTimeout(() => {
        setPopupShow(true);
        if (questions[question]?.answers[userAnswer]?.isCorrect) {
          setScore(score + 1);
          setPopupType('successPopup');
        } else {
          setPopupType('failurePopup');
        }
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [userAnswer, question]);

  const buttonType = (answerId) => {
    if (userAnswer === answerId) {
      return questions[question]?.answers[answerId]?.isCorrect
        ? 'success'
        : 'is-wrong';
    } else {
      return questions[question]?.answers[answerId]?.isCorrect
        ? 'is-correct'
        : '';
    }
  };

  let popupProps;
  if (popupType === 'exitPopup') {
    popupProps = exitPopupProps;
  } else if (popupType === 'timePopup') {
    popupProps = timePopupProps;
  } else if (popupType === 'successPopup') {
    popupProps = {
      ...successPopups[(question + 1) % successPopups.length],
      ...nextQuestionPopupProps
    };
  } else if (popupType === 'failurePopup') {
    popupProps = {
      ...failurePopups[(question + 1) % failurePopups.length],
      ...nextQuestionPopupProps
    };
  }
  return (
    <Container onContextMenu={(e) => e.preventDefault()}>
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: '100%', height: '100%' }}
        >
          <LoadingPopup>
            <div className="text-center justify-content-center align-items-center py-5 mt-4">
              <img
                width="442"
                src={`images/greenpanda/Quiz/PandasQuiz.svg`}
                alt="pandas_quiz"
              />
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: '200px', height: '200px', overflow: 'hidden' }}
            >
              <div
                className="loader"
                style={{
                  borderTop: '1.1em solid #808080',
                  borderBottom: '1.1em solid #808080',
                  borderRight: '1.1em solid #808080',
                  borderLeft: '1.1em solid #BBFFCC'
                }}
              ></div>
            </div>
            <p className="fs30 fw500 text-center p-5 m-5">
              <Trans i18nKey="quiz.Let’s have some fun while waiting for the identification. Let's play a quick quiz!" />
            </p>
          </LoadingPopup>
        </div>
      )}
      {!loading && (
        <>
          <ExitButton
            onClick={() => {
              setPopupShow(true);
              setPopupType('exitPopup');
            }}
          >
            <Trans i18nKey="Exit" />
          </ExitButton>
          {question < questions.length && (
            <>
              <Header>
                <h1 className="fontThicker fs38 d-flex justify-content-center pt-5 m-auto">
                  <Trans i18nKey="quiz.Pandas Quiz" />
                </h1>
                <p
                  className="fs22 d-flex justify-content-center pb-4 m-auto"
                  style={{ color: '#808080' }}
                >
                  <Trans i18nKey="quiz.Now, as we are identifying your device, let’s play a game!" />
                </p>
              </Header>
              <Background>
                <StatusBar>
                  <Row className="justify-content-center mx-0">
                    <Col xs="11" className="d-flex justify-content-between">
                      <p className="fs22">
                        <Trans i18nKey="quiz.Question" />
                        {question + 1}
                      </p>
                      <p className="fs22">
                        00:{timer < 10 ? `0${timer}` : timer}
                      </p>
                      <p className="fs22">
                        <Trans i18nKey="quiz.Score" />
                        {score}/{questions.length}
                      </p>
                    </Col>
                  </Row>
                </StatusBar>
                <Row
                  style={{ height: '40vh', width: '100%', margin: '0' }}
                  className="align-items-center justify-content-center"
                >
                  <Col
                    xs="10"
                    className="d-flex justify-content-center text-center"
                    style={{
                      fontSize: '48px',
                      fontWeight: '700',
                      color: 'white'
                    }}
                  >
                    <Trans
                      i18nKey={`quiz.questions.${questions[question].questionId}`}
                    />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center mx-0">
                  {questions[question]?.answers?.length > 0 &&
                    questions[question]?.answers.map((answer, index) => (
                      <QuizButton
                        key={index}
                        onClick={() => {
                          if (userAnswer !== null) return;
                          setUserAnswer(index);
                        }}
                        type={userAnswer === null ? '' : buttonType(index)}
                        text={`quiz.questions.${answer.id}`}
                        index={index}
                      ></QuizButton>
                    ))}
                </Row>
              </Background>
            </>
          )}
          {question >= questions.length && (
            <div
              style={{
                height: '100%',
                width: '100%',
                background: 'black',
                color: 'white'
              }}
            >
              <div className="text-center justify-content-center align-items-center pt-5 pb-4">
                <img
                  width="242"
                  src={`images/greenpanda/Quiz/PandasQuiz.svg`}
                  alt="pandas_quiz"
                />
              </div>
              <h1 className="fontThicker fs56 d-flex justify-content-center pt-5 m-auto">
                <Trans i18nKey="quiz.Well done!" />
              </h1>
              <Row className="justify-content-center my-5 mx-0">
                <Col xs="10">
                  <Result>
                    <div
                      style={{
                        position: 'relative',
                        left: '50%',
                        transform: 'translate(-125px, -106px)'
                      }}
                    >
                      <HexagonBorder />
                      <HexagonInner text={`${score}/${questions.length}`} />
                    </div>
                    <Trans i18nKey="quiz.We hope our little quiz gives you a better view of what a big, positive impact trade-ins can have for the environment and in closing the technology gap." />
                  </Result>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <PlayAgain
                  onClick={() => {
                    setQuestion(0);
                    setUserAnswer(null);
                    setScore(0);
                  }}
                >
                  <Trans i18nKey="quiz.Play again!" />
                </PlayAgain>
              </Row>
            </div>
          )}

          {popupShow && popupType && (
            <InfoPopup
              show={popupShow && props.showPopups}
              onHide={popupProps.onHide}
              text={popupProps.text}
              emoji={popupProps.emoji}
              img={popupProps.image}
              primaryButtonText={popupProps.primaryButtonText}
              primaryButtonOnClick={popupProps.primaryButtonOnClick}
              secondaryButtonText={popupProps.secondaryButtonText}
              secondaryButtonOnClick={popupProps.secondaryButtonOnClick}
              secondaryButtonTo={popupProps.secondaryButtonTo}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default withTranslation()(Quiz);
