import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context';
import { navigate } from '@gatsbyjs/reach-router';
import { MainImage, Icons } from '../../components';
import styled from 'styled-components';
import packageJson from '../../../package.json';
import { ReactComponent as NextArrow } from '../../images/next_arrow.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TutorialSlide from '../../components/TutorialSlide/TutorialSlide';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  padding: 5vh 5vw 2vh;
  background-image: url(images/${(props) => props.theme}/Tutorial/bg.png);
  background-position: center;
  background-size: cover;
  color: #ffffff !important;
`;
const NextButton = styled.div`
  width: 26vw;
  max-width: 420px;
  height: 13vh;
  max-height: 130px;
  padding: 0 3vw;
  background: #ffffff;
  color: ${({ theme: { colors } }) => colors.fonts};
  border-radius: ${({ theme: { primaryButton } }) => primaryButton.radius};
  font-size: 3rem;
  font-weight: 600;
  line-height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Slide = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5vw 5vh;
  height: 63vh;
  border-radius: 3.2rem;
`;

const Tutorial = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const [back, setBack] = useState(false);
  const [next, setNext] = useState(true);

  var slide = false;
  var backSlide = false;

  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'tutorial' });
  }, []);

  let maxStep, medStep;
  if (state.flow.tutorial.biometrics) {
    maxStep = 3;
    medStep = 2;
  } else {
    maxStep = 2;
    medStep = 1;
  }
  const SampleNextArrow = (props) => {
    const { className, style, onClick, currentSlide } = props;
    if (currentSlide === 1) {
      backSlide = false;
      setNext(true);
      setBack(true);
    }
    if (currentSlide === maxStep) {
      slide = true;
      setNext(false);
    }
    if (slide && currentSlide === 0)
      navigate(process.env.PUBLIC_URL + '/', { replace: true });
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'transparent' }}
        onClick={onClick}
      />
    );
  };

  function SamplePrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;
    if (currentSlide === medStep) {
      slide = false;
      setBack(true);
      setNext(true);
    }
    if (currentSlide === 0) {
      backSlide = true;
      setBack(false);
    }
    if (backSlide && currentSlide === maxStep) {
      navigate(process.env.PUBLIC_URL + '/timer/tutorial', { replace: true });
    }
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'transparent' }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    dotsClass: 'button__bar',
    arrows: true,
    nextArrow: <SampleNextArrow slide={false} />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <Layout theme={state.flow.theme} onContextMenu={(e) => e.preventDefault()}>
      <div className="d-flex justify-content-between">
        <div></div>
        {/* <MainImage img='Logos/logo_white.svg' height='6.8vh' /> */}
        <MainImage
          onClick={() =>
            navigate(process.env.PUBLIC_URL + '/', { replace: true })
          }
          img="Tutorial/exit_button.png"
          height="12.5vh"
        />
      </div>
      <Slide>
        <Slider style={{ height: '100%' }} {...settings}>
          <TutorialSlide
            img="1"
            title={<Trans i18nKey="tutorial.title1" />}
            subtitle={<Trans i18nKey="tutorial.subtitle1" />}
          />
          {state.flow.tutorial.biometrics && (
            <TutorialSlide
              img="2"
              title={<Trans i18nKey="tutorial.title2" />}
              subtitle={<Trans i18nKey="tutorial.subtitle2" />}
            />
          )}
          <TutorialSlide
            img="3"
            title={<Trans i18nKey="tutorial.title3" />}
            subtitle={<Trans i18nKey="tutorial.subtitle3" />}
          />
          <TutorialSlide
            img="4"
            title={<Trans i18nKey="tutorial.title4" />}
            subtitle={<Trans i18nKey="tutorial.subtitle4" />}
          />
        </Slider>
      </Slide>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          {back ? (
            <MainImage img="Tutorial/back_button.svg" height="12.5vh" />
          ) : (
            <div></div>
          )}
          {next ? (
            <MainImage img="Tutorial/next_button.svg" height="12.5vh" />
          ) : (
            <NextButton
              onClick={() =>
                navigate(process.env.PUBLIC_URL + '/', { replace: true })
              }
            >
              <Trans i18nKey="tutorial.finish" />
              <Icons
                className="ms-4"
                width="3vw"
                clr={({ theme: { colors } }) => colors.fonts}
              >
                {' '}
                <NextArrow />
              </Icons>
            </NextButton>
          )}
        </div>
        <div className="mt-5" style={{ opacity: 0.2 }}>
          {packageJson.version}
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation()(Tutorial);
