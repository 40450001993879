import { Trans } from "react-i18next";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../utils/context";
import { navigate } from "@gatsbyjs/reach-router";
import {
  MainLayout,
  Top,
  Center,
  Bottom,
} from "../../layouts/MainLayout/MainLayout";
import { MainCard, MainTitle, MainVideo } from "../../components";
import * as sdk from "../../actions/sdk";

const GetSim = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const [loading, setLoading] = useState(false);
  let isApiSubscribed = true;

  useEffect(() => {
    dispatch({ type: "userJourney", payload: "get-sim" });
    dispatch({ type: "progress", payload: "step2" });
    return () => {
      isApiSubscribed = false;
    };
  }, []);
  const onNextButtonClick = () => {
    setLoading(true);
    console.log("DISPENSE SIM");
    sdk
      .dispenseSim()
      .then(() => {
        if (isApiSubscribed)
          navigate(process.env.PUBLIC_URL + "/timer/insert-sim", {
            replace: true,
          });
      })
      .catch((e) => {
        // TODO: catch hardware errors
        console.log(e);
      });
  };

  return (
    <MainLayout
      progress_bar
      exit
      nextButton={{
        text: <Trans i18nKey="next_button" />,
        to: "#",
        onClick: onNextButtonClick,
        disabled: loading,
      }}
      backButton={{
        to: process.env.PUBLIC_URL + state.flow.get_sim.back_button,
        disabled: loading,
      }}
    >
      <Top></Top>
      <Center>
        <MainCard className="center">
          <MainVideo
            style={{ marginRight: "10vw" }}
            video="GetSim/get_sim.mp4"
          />
          <div style={{ width: "34vw" }}>
            <MainTitle className="py-3">
              <Trans i18nKey="get_sim.title" />
            </MainTitle>
            <div className="mt-5 fs26">
              <Trans i18nKey="get_sim.subtitle" />
            </div>
          </div>
        </MainCard>
      </Center>
      <Bottom></Bottom>
    </MainLayout>
  );
};

export default GetSim;
