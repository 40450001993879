import { Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Top,
  Center,
  Bottom
} from '../../layouts/MainLayout/MainLayout';
import {
  MainVideo,
  MainCard,
  Notification,
  MainTitle,
  InfoPopup
} from '../../components';
import tapScreen from './tapScreen.svg';

const Insert = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'insert' });
    dispatch({ type: 'progress', payload: 'step6' });
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 4000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <MainLayout
        progress_bar
        exit
        nextButton={{
          text: <Trans i18nKey="next_button" />,
          onClick: () => {
            setShow(true);
          }
        }}
      >
        <Top></Top>
        <Center>
          <MainCard className="center pt-5">
            <MainVideo
              style={{ marginRight: '2vw' }}
              video="Insert/insert.mp4"
            />
            <div>
              <MainTitle style={{ width: '45vw', marginBottom: '5vh' }}>
                <Trans
                  i18nKey={`insert.title_${state.flow.device_orientation}`}
                />
              </MainTitle>
            </div>
            <Notification loading={loading} type="info">
              <div>
                <Trans i18nKey="insert.notification" />
              </div>
            </Notification>
          </MainCard>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
      <InfoPopup
        show={show}
        onHide={() => setShow(false)}
        text="insert.instructionNotification"
        primaryButtonText="ok"
        primaryButtonTo={process.env.PUBLIC_URL + '/close-drawer'}
        img={tapScreen}
      />
    </>
  );
};

export default Insert;
