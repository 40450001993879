import React, { useRef, useState, useContext } from "react";
import { AppContext } from '../../utils/context';
import { Row, Col, Form, Modal } from 'react-bootstrap';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import styled from "styled-components";
import { Trans } from 'react-i18next';


const InputKeyboard = styled.input`
    width:100%;
    font-size: 30px;
    padding: 10px;
    margin: 10px;
    margin-right:30px;
    border: none;
    border-bottom: 1px solid;
    ::placeholder {
        font-size:30px;
    }
    &:focus{
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
    }
`;

const Submit = styled.div`
    background-color:${({ theme: { colors } }) => colors.primary};
    color: #FFFFFF;
    padding: 15px 25px;
    border-radius: 10px;
`;

const Input = (props) => {
    const { t, i18n, validation } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;

    const [input, setInput] = useState(state.userInformation['props.field']?state.userInformation['props.field']:'');
    const [inputValue, setInputValue] = useState(state.userInformation['props.field']?state.userInformation['props.field']:'');
    const [layout, setLayout] = useState("default");
    const keyboard = useRef();

    const onChange = input => {
        setInput(input);
    };

    const handleShift = () => {
        const newLayoutName = layout === "default" ? "shift" : "default";
        setLayout(newLayoutName);
    };

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
    };


    const onChangeInput = event => {
        const input = event.target.value;
        setInput(input + inputValue);
        keyboard.current.setInput(input + inputValue);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }

    const onClickInput = () => {
        setShow(true);
    }

    const handleSubmit = () => {
        setInputValue(input)
        setShow(false);

        var info = {};
        info[props.field] = input;
        dispatch({
            type: "input",
            payload: info
        })
    }
    let test = props.field

    return (
        <>
            <div>
                <Form.Group className={props.className} as={Col} controlId="formGridState">
                    <Form.Label className="fs20 fw700 mb-2 ms-1">{props.label}</Form.Label>
                    <Form.Control onClick={onClickInput} className={props.className} type="text" defaultValue={!props.value ? inputValue : props.value} placeholder={props.placeholder} />
                </Form.Group>
            </div>
            <Modal className="keyboard" show={show} onHide={handleClose} >
                <Modal.Body>
                    <Row className='d-flex justify-content-cente'>
                        <Col>
                            <div style={{ width: '100%' }} className='d-flex align-items-center mb-2'>
                                <InputKeyboard placeholder={input} className="" type="text" value={input}
                                    onChange={onChangeInput} />
                                <Submit className='fs30 fw500' onClick={() => handleSubmit()}><Trans i18nKey="done" /></Submit>
                            </div>
                            <Keyboard
                                keyboardRef={r => { r.setInput(inputValue); keyboard.current = r;}}
                                layoutName={layout}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    );
}

export default Input;