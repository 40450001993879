import { withTranslation, Trans } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/context";
import {
  MainLayout,
  Top,
  Center,
  Bottom,
} from "../../layouts/MainLayout/MainLayout";
import {
  MainVideo,
  MainCard,
  InfoButton,
  QRPopup,
  BorderQRCode,
  // Notification,
  MainTitle,
} from "../../components";
import QRCode from "react-qr-code";
import styled from "styled-components";
import axios from "axios";
// import { navigate } from "@gatsbyjs/reach-router";

const BorderBottom = styled.span`
  border-bottom: 8px solid ${({ theme: { colors } }) => colors.light_third};
  font-weight: 600;
`;

const WifiQR = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [wifiQRCode, setWifiQRCode] = useState(false);
  const [ssid, setSsid] = useState(
    state.sessionInfo.location && state.sessionInfo.location.id === 87
      ? "Orange_Swiatlowod_9222"
      : "KioskWifi" + parseInt(Math.random() * 100)
  );
  const [pass, setPass] = useState(
    state.sessionInfo.location && state.sessionInfo.location.id === 87
      ? "swiatlowod"
      : "Password" + parseInt(Math.random() * 100)
  );

  // const [ssid, setSsid] = useState('Orange_Swiatlowod_9222');
  // const [pass, setPass] = useState('swiatlowod');

  const header = { "Content-Type": "Access-Control-Allow-Origin" };
  useEffect(() => {
    dispatch({ type: "userJourney", payload: "wifi-qr" });
    dispatch({ type: "progress", payload: "step2" });
    // TODO: handle clean up
    axios
      .post(
        `${process.env.REACT_APP_URL}/v1/wifi/control/start`,
        { ssid: ssid, pass: pass },
        header
      )
      .then(function (response) {
        console.log(response);
        setWifiQRCode(`WIFI:S:${ssid};P:${pass};H:false;T:WPA;`);
        setLoading(false);
      })
      .catch(function (error) {
        // const timeout = setTimeout(() => {
        //     console.log("MOCK: wifi start");
        //     console.log("ssid", ssid);
        //     console.log("pass", pass);
        //     setWifiQRCode(`WIFI:S:${ssid};P:${pass};H:false;T:WPA;`)
        //     setLoading(false);
        // }, 1000);
        // return () => {
        //     clearTimeout(timeout);
        // }
      });
  }, []);
  // const cameraDoesntWork = () => {
  //   navigate(process.env.PUBLIC_URL + "/open-drawer", { replace: true });
  //   dispatch({ type: "sessionInfo", payload: { camera: false } });
  // };

  return (
    <>
      <MainLayout
        exit
        progress_bar
        nextButton={{
          text: <Trans i18nKey="next_button" />,
          to: process.env.PUBLIC_URL + state.flow.wifi_qr.next_button,
          disabled: loading,
        }}
        backButton={{
          to: process.env.PUBLIC_URL + state.flow.wifi_qr.back_button,
        }}
      >
        <Top></Top>
        <Center>
          <MainCard className="align-items-center justify-content-center">
            {wifiQRCode && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "400px", height: "400px" }}
              >
                <BorderQRCode>
                  <QRCode size={150} value={wifiQRCode} />
                </BorderQRCode>
              </div>
            )}
            {!wifiQRCode && <MainVideo video="WifiQR/wifi_qr.mp4" />}
            <div>
              <MainTitle className="mb-5 pb-5" style={{ width: "48vw" }}>
                <Trans i18nKey="wifi_qr.title" />
                <BorderBottom>
                  <Trans i18nKey="wifi_qr.border_bottom" />
                </BorderBottom>
              </MainTitle>
              <InfoButton className="me-5" onClick={() => setShow(true)}>
                <Trans i18nKey="wifi_qr.info_button" />
              </InfoButton>
            </div>
            {/* <Notification loading={loading}><u onClick={() => cameraDoesntWork()}><Trans i18nKey="wifi_qr.notification" /></u></Notification> */}
          </MainCard>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
      <QRPopup show={show} onHide={() => setShow(false)} />
    </>
  );
};

export default withTranslation()(WifiQR);
