import { Col } from 'react-bootstrap';
import { withTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';

const Button = styled.div`
  width: 596px;
  height: 128px;
  border: 2px solid ${(props) => props.border};
  background: ${(props) => props.background};
  border-radius: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  font-size: 30px;
  line-height: 48px;
  color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  margin: 10px;
`;

function background(type) {
  switch (type) {
    case 'is-correct':
      return '#BBFFCC40';
    case 'is-wrong':
      return '#FF4B5580';
    case 'success':
      return '#BBFFCC';
    default:
      return '#00000080';
  }
}

function color(type) {
  switch (type) {
    case 'is-correct':
      return '#FFFFFF';
    case 'is-wrong':
      return '#FFFFFF';
    case 'success':
      return '#000000';
    default:
      return '#FFFFFF';
  }
}

function border(type) {
  switch (type) {
    case 'is-correct':
      return '#BBFFCC';
    case 'is-wrong':
      return '#FF4B55';
    case 'success':
      return '#BBFFCC';
    default:
      return '#808080';
  }
}

function sideborder(type) {
  switch (type) {
    case 'is-correct':
      return '#808080';
    case 'is-wrong':
      return '#FF4B55';
    case 'success':
      return '#808080';
    default:
      return '#808080';
  }
}

const buttonOnderMapping = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D'
};

const QuizButton = ({ onClick, type, index, text }) => {
  return (
    <Button
      background={background(type)}
      color={color(type)}
      border={border(type)}
      onClick={onClick}
    >
      <Col
        xs="1"
        className="d-flex justify-content-center pr-4"
        style={{
          borderRight: `2px solid ${sideborder(type)}`,
          lineHeight: '60px'
        }}
      >
        <Trans i18nKey={`quiz.buttons.${buttonOnderMapping[index]}`} />
        {'.'}
      </Col>
      <Col xs="10" className="d-flex justify-content-center text-center">
        <Trans i18nKey={text} />
      </Col>
    </Button>
  );
};

export default withTranslation()(QuizButton);
