import { withTranslation } from 'react-i18next';
import React, { useContext } from "react";
import { MainImage } from '../../components';
import { Modal } from 'react-bootstrap';
import { AppContext } from '../../utils/context';

const SelectLanguage = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    
    return ( <>
        {state.flow &&
        <Modal className="language" show={props.show} onHide={props.onHide} size="sm" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body>
                <div className='center'>
                    <div>
                        <div onClick={props.firstLanguage} className='center my-4'>
                            <MainImage img={`Languages/${state.flow.languages.first}.svg`} height='' />
                            <div className='fs24  ms-5'>{state.flow.languages.firstLng}</div>
                        </div>
                        <div style={{ border: '1px solid #E5E4E5' }}></div>
                        <div onClick={props.secondLanguage} className='center my-4'>
                            <MainImage img={`Languages/${state.flow.languages.second}.svg`} height='' />
                            <div className='fs24  ms-5'>{state.flow.languages.secondLng}</div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        };
    </>
    );
}

export default withTranslation()(SelectLanguage);