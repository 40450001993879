import { withTranslation, Trans } from "react-i18next";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../utils/context";
import {
  MainLayout,
  Top,
  Center,
  Bottom,
} from "../../layouts/MainLayout/MainLayout";
import { MainVideo, MainCard, MainTitle } from "../../components";
import { navigate } from "@gatsbyjs/reach-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as sdk from "../../actions/sdk";

const Drawer = (props) => {
  return (
    <>
      <MainLayout>
        <Top></Top>
        <Center>
          <MainCard className="align-items-center justify-content-center">
            {props.open && (
              <MainVideo
                style={{ marginRight: "5vw" }}
                video="OpenDrawer/open_drawer.mp4"
              />
            )}
            {props.close && (
              <MainVideo
                style={{ marginRight: "5vw" }}
                video="CloseDrawer/close_drawer.mp4"
              />
            )}
            {props.open_device && (
              <MainVideo
                style={{ marginRight: "5vw" }}
                video="DeviceOpenDrawer/device_open_drawer.mp4"
              />
            )}
            {(props.open || props.open_device) && (
              <MainTitle style={{ width: "45vw" }}>
                <Trans i18nKey="open_drawer" />
              </MainTitle>
            )}
            {props.close && (
              <MainTitle style={{ width: "45vw" }}>
                <Trans i18nKey="close_drawer" />
              </MainTitle>
            )}
          </MainCard>
          <ToastContainer position="bottom-left" />
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default withTranslation()(Drawer);
