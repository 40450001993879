import { withTranslation, Trans } from 'react-i18next';
import React, { useEffect } from 'react';
import { navigate } from '@gatsbyjs/reach-router';
import { MainTitle } from '../../components';
import styled from 'styled-components';
import { businessHours, isOpen } from '../../utils/operatingHours';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const CHECK_INTERVAL = process.env.REACT_APP_OPERATING_HOURS_CHECK_INTERVAL
  ? parseInt(process.env.REACT_APP_OPERATING_HOURS_CHECK_INTERVAL)
  : 60000;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  padding: 5vh 5vw 2vh;
  background-image: url(images/greenpanda/Tutorial/bg.png);
  background-position: center;
  background-size: cover;
  color: #ffffff !important;
`;
const Box = styled.div`
  padding: 3rem;
  border-radius: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around
`;

const HoursBox = styled.div`
margin: 2rem 0;
border-right: 3px solid #4D4D4D;
padding-right: 4rem;
&:last-child {
  border: none;
  padding-right: 0rem;

}
`
const OperatingHours = () => {

  useEffect(() => {
    const interval = setInterval(() => {
      if (isOpen()) {
        navigate(process.env.PUBLIC_URL + '/', {
          replace: true
        });
      };
    }, CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout onContextMenu={(e) => e.preventDefault()}>
      <div className="d-flex justify-content-center align-items-center " style={{ height: '100vh' }}>
        <Box style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", height: '85vh' }}>
          <div className='my-5'>
            <img src={`images/greenpanda/OperatingHours/pandas-p-white.svg`} alt={'logo'} style={{ maxHeight: '25vh', height: '100%' }} />
          </div>
          <MainTitle className="m-3 text-center" style={{
            color: '#CCCCCC', width: '70%'
          }}><Trans i18nKey="operating_hours.title" /></MainTitle>
          <Box style={{ backgroundColor: "#333333", width: '80%', marginTop: '2rem', flexDirection: "row" }}>
            {businessHours.map((interval, index) => (
              <HoursBox key={index} className="flex text-center" >
                <div style={{ fontSize: '30px' }}>
                  <b>
                    <Trans i18nKey={`operating_hours.${interval.daysOfWeek[0]}`} />&nbsp;</b>
                  {(interval.daysOfWeek.length - 1) !== 0 && <b>-&nbsp;
                    <Trans i18nKey={`operating_hours.${interval.daysOfWeek[interval.daysOfWeek.length - 1]}`} />
                  </b>}
                </div>
                {interval.openHours.map((hours, index) => (
                  <div className="fontThicker" style={{ fontSize: '56px', color: '#BBFFCC' }} key={index}>
                    {hours.openHour}:00&nbsp;<span className="fontThicker" style={{ fontSize: '56px', color: 'white' }}>-</span>&nbsp;{hours.closeHour}:00
                  </div>
                ))}
              </HoursBox>
            ))}
          </Box>
          <Box style={{ backgroundColor: "#333333", width: '80%', marginBottom: '2rem' }}>
            <p style={{ fontSize: '32px' }}><FontAwesomeIcon icon={faPhoneAlt} />&nbsp;&nbsp;211 1982521</p>
          </Box>
        </Box>
      </div>


    </Layout >
  );
};

export default withTranslation()(OperatingHours);
