import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainCard, ConditionTag, MainTitle } from '../../components';
import GoodIcon from '../../images/good.svg';
import PoorIcon from '../../images/poor.svg';
import FaultyIcon from '../../images/faulty.svg';

const DetailedReport = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const brand = state.deviceInfo ? state.deviceInfo.brand : '';
    const model = state.deviceInfo ? state.deviceInfo.model : '';
    const color = state.deviceInfo ? state.deviceInfo.color : '';
    const capacity = state.deviceInfo ? state.deviceInfo.capacity : '';
    const condition = state.gradingInfo.condition;
    if (condition === 'good') {
        var text = <Trans i18nKey="detailed_report.minor" />
    }
    if (condition === 'poor') {
        var text = <Trans i18nKey="detailed_report.deep" />
    }

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'detailed-report' })
        dispatch({ type: "progress", payload: 'step6' })
    }, [])


    return (
        <>
            <MainLayout
                exit
                backButton={{ to: process.env.PUBLIC_URL + '/offer' }}
                progress_bar
            >
                <Top></Top>
                <Center>
                    <MainCard className='d-flex flex-column justify-content-center' style={{ padding: '6vh 5vw' }} large>
                        <div className='mb-5'>
                            <MainTitle><Trans i18nKey="detailed_report.title" /></MainTitle>
                            <div className='fs30 fontGray mt-3'>{brand} {model} {brand === 'Apple' && capacity + ' ' + color}</div>
                        </div>
                        <ConditionTag />
                        <div className='d-flex mt-5'>
                            <div style={{ width: '90%' }}>
                                <div className='d-flex align-items-center fs30 my-4'>
                                    <img className='me-4' src={GoodIcon} />
                                    <div><Trans i18nKey="detailed_report.calls_internet" /></div>
                                </div>
                                <div className='d-flex align-items-center fs30 my-4'>
                                    {condition === 'good' | condition === 'poor' ? <>
                                        <img className='me-4' src={PoorIcon} />
                                        <div>{text}<Trans i18nKey="detailed_report.front_glass_good" /></div></> : <>
                                        <img className='me-4' src={state.gradingInfo.hasScreenDamage ? FaultyIcon : GoodIcon} />
                                        <div>{state.gradingInfo.hasScreenDamage ? <Trans i18nKey="detailed_report.front_glass_faulty" /> : <Trans i18nKey="detailed_report.front_glass_like_new" />}</div></>
                                    }
                                </div>
                                <div className='d-flex align-items-center fs30 my-4'>
                                    {condition === 'good' | condition === 'poor' ? <>
                                        <img className='me-4' src={PoorIcon} />
                                        <div>{text} <Trans i18nKey="detailed_report.back_side_good" /></div></> : <>
                                        <img className='me-4' src={state.gradingInfo.hasGeneralDamage ? FaultyIcon : GoodIcon} />
                                        <div>{state.gradingInfo.hasGeneralDamage ? <Trans i18nKey="detailed_report.back_side_faulty" /> : <Trans i18nKey="detailed_report.back_side_like_new" />}</div></>
                                    }
                                </div>
                                <div className='d-flex align-items-center fs30 my-4'>
                                    <img className='me-4' src={(condition === 'good' || condition === 'poor') ? PoorIcon : (state.gradingInfo.hasDentsOnSidesDamage) ? FaultyIcon : GoodIcon} />
                                    {condition === 'good' | condition === 'poor' ?
                                        <div>{text}<Trans i18nKey="detailed_report.frame_good" /></div> :
                                        <div>{state.gradingInfo.hasDentsOnSidesDamage ? <Trans i18nKey="detailed_report.frame_faulty" /> : <Trans i18nKey="detailed_report.frame_like_new" />}</div>
                                    }
                                </div>
                                <div className='d-flex align-items-center fs30 my-4'>
                                    <img className='me-4' src={state.gradingInfo.hasWaterDamage ? FaultyIcon : GoodIcon} />
                                    <div>{state.gradingInfo.hasWaterDamage ? <Trans i18nKey="detailed_report.lcd_faulty" /> : <Trans i18nKey="detailed_report.lcd" />}</div>
                                </div>
                            </div>
                            {(state.sessionInfo.flow === 'trade_in') && <div style={{ width: '46%' }} className='ms-5'>
                                <div className='d-flex align-items-center fs30 my-4'>
                                    <img className='me-4' src={state.webappInfo.digitizer === '0' ? FaultyIcon : GoodIcon} />
                                    <div><Trans i18nKey="detailed_report.touchscreen" /></div>
                                </div>
                                <div className='d-flex align-items-center fs30 my-4'>
                                    <img className='me-4' src={state.webappInfo.front_camera === '0' | state.webappInfo.back_camera === '0' ? FaultyIcon : GoodIcon} />
                                    <div><Trans i18nKey="detailed_report.camera" /></div>
                                </div>
                                <div className='d-flex align-items-center fs30 my-4'>
                                    <img className='me-4' src={state.webappInfo.sound === '0' ? FaultyIcon : GoodIcon} />
                                    <div><Trans i18nKey="detailed_report.sound" /></div>
                                </div>
                                <div className='d-flex align-items-center fs30 my-4'>
                                    <img className='me-4' src={state.webappInfo.biometrics === '0' ? FaultyIcon : GoodIcon} />
                                    <div><Trans i18nKey="detailed_report.biometrics" /></div>
                                </div>
                            </div>}
                        </div>
                    </MainCard>
                </Center>
                <Bottom>
                </Bottom>
            </MainLayout>
        </>
    );
}

export default withTranslation()(DetailedReport);