import { Trans } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { AppContext } from "../../utils/context";
import {
  MainLayout,
  Top,
  Center,
  Bottom,
} from "../../layouts/MainLayout/MainLayout";
import { MainImage, MainCard, Notification, MainTitle } from "../../components";
import { navigate } from "@gatsbyjs/reach-router";
import * as sdk from "../../actions/sdk";

const CardReader = (props) => {
  const { t, i18n } = props;
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch({ type: "userJourney", payload: "card-reader" });
    console.log("READING CARD");
    sdk
      .cardReader()
      .then((res) => {
        console.log(res);
        dispatch({
          type: "input",
          payload: {
            payment_value: res.data.number,
            cardNumber: res.data.number,
            payment_method: "card",
          },
        });
        setDone(true);
        setTimeout(() => {
          navigate(process.env.PUBLIC_URL + "/user-information", {
            replace: true,
          });
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setTimeout(() => {
          navigate(process.env.PUBLIC_URL + "/payment-options", {
            replace: true,
          });
        }, 5000);
      });
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
      <MainLayout
        progress_bar
        exit
        backButton={{
          to: process.env.PUBLIC_URL + "/payment-options",
        }}
      >
        <Top></Top>
        <Center>
          <MainCard className="center pt-5">
            <div className="mt-5 pt-5">
              {!done && (
                <MainImage
                  className="pt-5"
                  img="CardReader/nfc_card.png"
                  height="42vh"
                />
              )}
              {done && (
                <MainImage
                  className="pt-5"
                  img="CardReader/nfc_success.png"
                  height="42vh"
                />
              )}
            </div>
            <div className="mb-5">
              <MainTitle style={{ width: "45vw" }}>
                <Trans i18nKey="card_reader.title" />
              </MainTitle>
            </div>
          </MainCard>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default CardReader;
