export const errorMapping = {
  'Χμμ.. φαίνεται ότι η οθόνη σας είναι σβηστή. Τοποθετήστε τη συσκευή με ανοιχτή την οθόνη στο συρτάρι.': 'turn-on-screen',
  'Παρακαλώ τοποθετείστε το κινητό μέσα στο πράσινο πλαίσιο με την οθόνη να κοιτάει προς τα πάνω.': 'align-phone',
  'Παρακαλώ τοποθετείστε ένα κινητό την φορά.': 'align-phone',
  'Αυτή τη στιγμή δεν μπορούμε να αξιολογήσουμε συσκευές με σβηστή οθόνη. Θα χαρούμε να σας εξυπηρετήσουμε σύντομα ξανά!': 'good-bye',
  'Παρακαλώ αφαιρέστε τη θήκη και το προστατευτικό τζαμάκι οθόνης.': 'remove-cover',
  'align-phone': 'align-phone',
  'empty-drawer': 'empty-drawer',
  'turn-on-screen': 'turn-on-screen',
  'remove-cover': 'remove-cover',
  'remove-film': 'remove-film',
  'good-bye': 'good-bye',
  'clean-phone': 'clean-phone'
}

export const gradingErrors = ['price-not-found', 'empty-drawer', 'turn-on-screen', 'align-phone', 'good-bye', 'remove-cover', 'remove-film', 'clean-phone']
export function transformGradingData(data) {
  if (data.isImageClear === false)
    throw new Error('empty-drawer');
  else if (data.hasMinorScratchDamage)
    throw new Error('price-not-found');
  else if (data.phonePositionAnswer)
    throw new Error(errorMapping[data.phonePositionAnswer]);
  else {
    let condition;
    if (
      data.hasWaterDamage |
      data.hasScreenDamage |
      data.hasGeneralDamage |
      data.hasButtonDamage)
      condition = 'faulty';
    else if (data.hasDentsOnSidesDamage)
      condition = 'poor';
    else if (data.isExceptional)
      condition = 'very_good'
    else
      condition = 'good'
    return condition
  }
}