import { withTranslation } from "react-i18next";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../utils/context";

import axios from "axios";
import { navigate } from "@gatsbyjs/reach-router";

const Voucher = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;

  useEffect(() => {
    dispatch({ type: "userJourney", payload: "voucher" });
    if (state.flow.payment_methods.voucher) {
      axios
        .get(
          `https://session-service.greenpanda.io/voucher/code/${state.flow.partner.code}/${state.deviceInfo.imei}`
        )
        .then((res) => {
          console.log("VOUCHER ", JSON.stringify(res.data));
          dispatch({
            type: "input",
            payload: {
              payment_value: res.data.code,
              payment_method: "voucher",
            },
          });
          navigate(process.env.PUBLIC_URL + "/user-information");
        });
    } else {
      navigate(process.env.PUBLIC_URL + "/payment-options");
    }
  }, []);

  return <></>;
};

export default withTranslation()(Voucher);
