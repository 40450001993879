import { withTranslation, Trans } from 'react-i18next';
import { Popup, MainImage, ActionButton } from '../..';

const SideLayoutPopup = (props) => {
  return (
    <Popup show={props.show} onHide={props.onHide}>
      <div className="center flex-column">
        <div className="center">
          <MainImage style={{ marginRight: '8vw' }} img={props.image} />
          <div className="d-flex flex-column my-5 pb-5">
            <div className="fs38 lh48 fw600 mb-5">{props.title}</div>
            <div className="fs30 lh40  mb-5 ">{props.subtitle}</div>
          </div>
        </div>
        <div className="d-flex">
          <ActionButton
            className="mx-4"
            to={process.env.PUBLIC_URL + props.nextButtonLink}
            width="27vw"
          >
            <Trans i18nKey="next_button" />
          </ActionButton>
        </div>
      </div>
    </Popup>
  );
};

export default withTranslation()(SideLayoutPopup);
