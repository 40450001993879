import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Center,
  BottomLeft,
  Top
} from '../../layouts/MainLayout/MainLayout';
import {
  ExitPopup,
  FmiSimDeactivatePopup,
  MainImage,
  MainTitle,
  InfoPopup
} from '../../components';
import { navigate } from '@gatsbyjs/reach-router';
import axios from 'axios';
import Quiz from '../../components/Quiz/Quiz';
import { Row, Col } from 'react-bootstrap';
import { io } from 'socket.io-client';
import { errorMapping, gradingErrors, transformGradingData } from '../../utils/transformers';

const PROCESS_TIMEOUT = 300000;
const GPSIM_IMSI_CHECK_DIGITS = '11199';

const Inspection = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const [show, setShow] = useState(false);
  const [exitPopupShow, setExitPopupShow] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [identificationSuccess, setIdentificationSuccess] = useState(false);
  const [showIdentificationSuccessPopup, setShowIdentificationSuccessPopup] =
    useState(false);
  let identificationConfirmation =
    state.userJourney.indexOf('close-drawer-complete') !== -1;

  let socket;

  useEffect(() => {
    socketInitializer();
    if (identificationConfirmation) {
      dispatch({ type: 'userJourney', payload: 'inspection-complete' });
    } else {
      dispatch({ type: 'userJourney', payload: 'inspection' });
    }
    const inspectionTimeout = setTimeout(async () => {
      navigate(process.env.PUBLIC_URL + '/identification-failed', {
        replace: true
      });
    }, PROCESS_TIMEOUT);

    return () => {
      socket?.disconnect();
      clearTimeout(inspectionTimeout);
    };
  }, []);

  const imeiServiceCall = async (data) => {
    const res = await axios.post(`${process.env.REACT_APP_IMEI_SERVICE_URL}/check`, {
      imei: data.imei
    });
    return res.data;
  }

  function popupType() {
    if (state.check.fmi && state.check.sim) return 'fmi_sim';
    else if (state.check.fmi) return 'fmi';
    else if (state.check.sim) return 'sim';
    else return 'error';
  }
  const socketInitializer = async () => {
    socket = io(`http://localhost:3005`, {
      path: '/sdk/socket.io'
    });

    socket.on('connect', () => {
      socket.on('assesmentSessionData', async (data) => {
        console.log('got assessmentData');
        dispatch({ type: 'deviceInfo', payload: { photos: data.photos } });
        dispatch({ type: 'sessionInfo', payload: { evaluation: 1 } });
        dispatch({ type: 'gradingInfo', payload: data.remoteGrading });
        const devices = data.devices;
        if (devices.length === 0) {
          navigate(process.env.PUBLIC_URL + '/identification-failed', {
            replace: true
          });
          return;
        }
        let imeis = devices
          .map((a) => a.imei)
          //filter to get unique imeis
          .filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
          });
        if (identificationConfirmation && !(imeis.includes(state.deviceInfo.imei) || imeis.includes(state.deviceInfo.imei2))) {
          navigate(process.env.PUBLIC_URL + '/price-not-found', {
            replace: true
          });
          return;
        } else {
          dispatch({
            type: 'deviceInfo',
            payload: {
              identify: devices,
              imei: imeis[0],
              imei2: imeis[1],
              imsi: devices[0].imsi
            }
          });
          try {
            const deviceInfoData = await imeiServiceCall(devices[0]);
            dispatch({ type: 'sessionInfo', payload: { identification: 1 } });
            dispatch({ type: 'fmi_sim', payload: { fmi: (deviceInfoData.fmiOn || deviceInfoData.fmiON) } });
            let imsiCheck = devices[0].imsi ? devices[0].imsi.slice(0, 5) : GPSIM_IMSI_CHECK_DIGITS;
            dispatch({ type: 'fmi_sim', payload: { sim: (imsiCheck !== GPSIM_IMSI_CHECK_DIGITS) } });
            if (identificationConfirmation) {
              if (popupType() !== 'error') {
                setShow(true);
              }
            } else {
              //CALCULATE OFFER
              const condition = transformGradingData(data.remoteGrading);
              dispatch({ type: 'sessionInfo', payload: { offer: 1 } });
              dispatch({ type: 'gradingInfo', payload: { condition } });
              dispatch({
                type: 'priceInfo',
                payload: {
                  price: Math.round(deviceInfoData.price * deviceInfoData[condition]),
                  localPrice: Math.round(
                    deviceInfoData.localPrices[state.flow.localPrices] *
                    deviceInfoData[condition]
                  ),
                  basePrice: Math.round(deviceInfoData.price * deviceInfoData[condition]),
                  localBasePrice: Math.round(
                    deviceInfoData.localPrices[state.flow.localPrices] *
                    deviceInfoData[condition]
                  )
                }
              });
              setIdentificationSuccess(true);
              setShowIdentificationSuccessPopup(true);
            }
            dispatch({
              type: 'deviceInfo',
              payload: { ...deviceInfoData }
            });
          } catch (error) {
            if (gradingErrors.includes(error?.message)) {
              navigate(process.env.PUBLIC_URL + `/${error.message}`, {
                replace: true
              });
            } else if (error?.response?.data?.error === 'Phone is already recycled' || error?.response?.data?.error === 'Price not found')
              navigate(process.env.PUBLIC_URL + '/price-not-found', {
                replace: true
              });
            else
              navigate(process.env.PUBLIC_URL + '/identification-failed', {
                replace: true
              });
          }
        }
      });

      socket.on('error', (data) => {
        let error
        if (data.source === 'pregrade') {
          error = (errorMapping[data.body.errorType]);
          console.info(
            `Pregrade error with type: ${error}`
          );
        } else if (data.source === 'grade') {
          data.body.data.isImageClear
            ? error = errorMapping[data.body.data.phonePositionAnswer]
            : error = errorMapping['empty-drawer'];
          console.info(
            `Gradding error with type ${error}`
          );
        } else if (data.source === 'identify') {
          error = 'identification-failed';
          console.info(
            `Identification error: no devices recognized`
          );
        } else {
          error = 'good-bye';
          console.error(`Unexpected error emitted from sdk`, { error: data });
        }
        navigate(
          `${process.env.PUBLIC_URL}/${error}`,
          { replace: true }
        );
      });
    });
  };
  return (
    <>
      {showQuiz && (
        <>
          <Quiz
            showPopups={!showIdentificationSuccessPopup}
            onHide={() => {
              setShowQuiz(false);
            }}
            to={
              identificationSuccess
                ? process.env.PUBLIC_URL + '/offer'
                : null
            }
          ></Quiz>
          {!identificationSuccess && (
            <Row
              className="align-items-center m-0"
              style={{ background: 'white', height: '12vh' }}
            >
              <Col xs="9" className="ps-5">
                <p className="fs30 fw500 ps-4 py-3">
                  {!identificationConfirmation && (
                    <Trans i18nKey="inspection.We are identifying your device" />
                  )}
                  {identificationConfirmation && (
                    <Trans i18nKey="inspection.We’re checking your device" />
                  )}
                </p>
                <div className="d-flex pb-5" style={{ width: '100%' }}>
                  <div className="loader-line-identifying mx-4"></div>
                </div>
              </Col>
              <Col xs="3">
                <div className="d-flex justify-content-center">
                  <div
                    onClick={() => setExitPopupShow(true)}
                    className="fs30 px-5 py-4"
                    style={{
                      border: '2px solid #F2F2F2',
                      borderRadius: '6.5rem'
                    }}
                  >
                    <Trans i18nKey="inspection.cancel" />
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {identificationSuccess && (
            <Row
              className="align-items-center m-0"
              style={{ background: 'white', height: '12vh' }}
            >
              <Col xs="8" className="ps-5">
                <p className="fs24 fw500 ps-4 pt-3 mb-0">
                  <Trans i18nKey="inspection.We identified your device!" />
                </p>
                <p className="fs32 ps-4 pb-1">
                  <b>
                    {state.deviceInfo.brand} {state.deviceInfo.model}
                  </b>
                  {state.deviceInfo.brand === 'Apple'
                    ? ' ' +
                    state.deviceInfo.capacity +
                    '/' +
                    state.deviceInfo.color
                    : ''}
                </p>
              </Col>
              <Col xs="4">
                <div className="d-flex justify-content-center">
                  <div
                    onClick={() =>
                      navigate(process.env.PUBLIC_URL + '/offer', {
                        replace: true
                      })
                    }
                    className="fs30 py-4"
                    style={{
                      background: '#1A1A1A',
                      borderRadius: '6.5rem',
                      color: 'white',
                      paddingInline: '45px'
                    }}
                  >
                    <Trans i18nKey="inspection.Get offer" />
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
      {!showQuiz && (
        <>
          <MainLayout noLogo exit>
            <Top></Top>
            <Center style={{ width: '100%', height: '100%' }}>
              <div className="flex-column center" style={{ width: '100%' }}>
                <MainImage img="Inspection/inspection.gif" />
                <MainTitle
                  className="my-5 text-center"
                  style={{ width: '47vw' }}
                >
                  {!identificationConfirmation && (
                    <Trans i18nKey="inspection.We are evaluating your device" />
                  )}
                  {identificationConfirmation && (
                    <Trans i18nKey="inspection.We’re checking your device" />
                  )}
                </MainTitle>
                <div className="d-flex py-5" style={{ width: '100%' }}>
                  <div className="loader-line-identifying mx-4"></div>
                </div>
              </div>
            </Center>
            <BottomLeft>
            </BottomLeft>
          </MainLayout>
          <div
            style={{
              width: '100%',
              height: '12vh',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              background: '#1A1A1A',
              position: 'absolute',
              bottom: '0'
            }}
          >
            <div className="text-center justify-content-center align-items-center">
              <img
                width="200"
                src={`images/greenpanda/Quiz/PandasQuiz.svg`}
                alt="pandas_quiz"
              />
            </div>
            <p className="fontThicker fs38 m-0" style={{ color: 'white' }}>
              <Trans i18nKey={`quiz.Up for a game while waiting?`} />
            </p>
            <p
              onClick={() => setShowQuiz(true)}
              style={{
                background: '#BBFFCC',
                borderRadius: '65px',
                padding: '20px 45px',
                fontSize: '34px',
                fontWeight: '500',
                margin: '0'
              }}
            >
              <Trans i18nKey="quiz.Let’s play!" />
            </p>
          </div>
        </>
      )}
      <ExitPopup show={exitPopupShow} onHide={() => setExitPopupShow(false)} />
      {identificationConfirmation && popupType() !== 'error' && (
        <FmiSimDeactivatePopup
          show={show}
          onHide={() => setShow(false)}
          type={popupType()}
          nextButtonLink={state.flow.offer.next_button.fmi_sim}
        ></FmiSimDeactivatePopup>
      )}
      <InfoPopup
        style={{ zIndex: '10' }}
        show={showIdentificationSuccessPopup}
        onHide={() => {
          showQuiz
            ? setShowIdentificationSuccessPopup(false)
            : navigate(process.env.PUBLIC_URL + '/offer', {
              replace: true
            });
        }}
        title="inspection.We identified your device!"
        text={`${state.deviceInfo.brand} ${state.deviceInfo.model}`}
        subtitle={
          state.deviceInfo.brand === 'Apple'
            ? state.deviceInfo.capacity + '/' + state.deviceInfo.color
            : ''
        }
        img={`images/${state.flow.theme}/IdentificationSuccess/identificationSuccess.png`}
        primaryButtonText={`inspection.Get offer`}
        primaryButtonTo={process.env.PUBLIC_URL + '/offer'}
      />
    </>
  );
};

export default withTranslation()(Inspection);
