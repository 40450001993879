import { withTranslation, Trans } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/context";
import {
  MainLayout,
  Top,
  Center,
  Bottom,
} from "../../layouts/MainLayout/MainLayout";
import { Input, MainImage } from "../../components";
import styled from "styled-components";
import { navigate } from "@gatsbyjs/reach-router";
import * as sdk from "../../actions/sdk";

const ZigZag = styled.div`
  height: 33vh;
  background: #ffffff;
  padding: 3vh 3vw;
  position: relative;
  &:after {
    top: 100%;
    background: linear-gradient(135deg, #ffffff 33.333%, transparent 0),
      linear-gradient(-135deg, #ffffff 33.333%, transparent 0);
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 4em;
    background-size: 6em 100%;
    background-position: center;
  }
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 4px);
`;
const Card = styled.div`
  height: 35vh;
  background: #fafafa;
  border: 2px dashed #e6e6e6;
  border-radius: 0.8rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;

const ConfirmPayment = (props) => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const brand = state.deviceInfo.brand ? state.deviceInfo.brand : "";
  const model = state.deviceInfo.model ? state.deviceInfo.model : "";
  const color = state.deviceInfo.color ? state.deviceInfo.color : "";
  const capacity = state.deviceInfo.capacity ? state.deviceInfo.capacity : "";
  const finalPrice = state.priceInfo.localPrice
    ? state.priceInfo.localPrice
    : "";
  const fullName = state.userInformation.name ? state.userInformation.name : "";
  const email = state.userInformation.email ? state.userInformation.email : "";
  const phone = state.userInformation.phone ? state.userInformation.phone : "";
  const payment_method = state.userInformation.payment_method
    ? state.userInformation.payment_method
    : "voucher";
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch({ type: "userJourney", payload: "confirm-payment" });
    dispatch({ type: "progress", payload: "step8" });
    console.log("DEVICE INFO ", JSON.stringify(state.deviceInfo));
    console.log("GRADING INFO ", JSON.stringify(state.gradingInfo));
    console.log("SESSION INFO ", JSON.stringify(state.sessionInfo));
    console.log("PRICE INFO ", JSON.stringify(state.priceInfo));
    console.log("JOURNEY ", JSON.stringify(state.userJourney));
    console.log("USER INFO ", JSON.stringify(state.userInformation));
  }, []);

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "_")
      .replace(/[^\w-]+/g, "");
  }

  const EditDetails = (props) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="fs16 fontGray">{props.title}</div>
        <div
          style={{ width: "100%" }}
          className="d-flex align-items-center justify-content-between"
        >
          <Input
            className="confirm_input"
            value={props.value}
            field={props.field}
          />
          <MainImage
            className="ms-5"
            img="ConfirmPayment/edit_icon.svg"
            height="15vh"
          />
        </div>
      </div>
    );
  };
  const onFinish = () => {
    setDisabled(true);
    console.log("COMPLETE TRANSACTION");
    sdk
      .completeTransaction(state.deviceInfo.imei)
      .then((response) => {
        setDisabled(false);
        console.log(response);
        dispatch({ type: "sessionInfo", payload: { transaction: 1 } });
        navigate(process.env.PUBLIC_URL + "/timer/thank-you-page", {
          replace: true,
        });
      })
      .catch((e) => {
        // TODO: catch hardware errors
        console.log(e);
      });
  };
  return (
    <>
      <MainLayout
        progress_bar
        exit
        nextButton={{
          text: <Trans i18nKey="confirm_payment.next_button" />,
          to: "#",
          onClick: () => onFinish(),
          disabled: disabled,
        }}
        backButton={{ to: process.env.PUBLIC_URL + "/user-information" }}
      >
        <Top></Top>
        <Center>
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: "100%" }}
          >
            <div
              className="d-flex justify-content-between pb-5 mb-5"
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-column" style={{ width: "49%" }}>
                <div className="fs30 fw600 mb-5">
                  <Trans i18nKey="confirm_payment.details" />
                </div>
                <Card>
                  <EditDetails
                    title={<Trans i18nKey="confirm_payment.full_name" />}
                    value={fullName}
                    field="name"
                  />
                  <EditDetails title="EMAIL" value={email} field="email" />
                  <EditDetails
                    title={<Trans i18nKey="confirm_payment.phone" />}
                    value={phone}
                    field="phone"
                  />
                  {payment_method === "voucher" ? (
                    <>
                      <div className="ms-1 fs16 fontGray">
                        {payment_method.toUpperCase()}
                      </div>
                      <div className="ms-2 fs20 fw700">
                        {state.userInformation.payment_value}
                      </div>
                    </>
                  ) : (
                    <EditDetails
                      title={payment_method.toUpperCase()}
                      value={state.userInformation.payment_value}
                      field="payment_value"
                    />
                  )}
                </Card>
              </div>
              <div className="d-flex flex-column" style={{ width: "49%" }}>
                <div className="fs30 fw600 mb-5">
                  <Trans i18nKey="confirm_payment.trade_in" />
                </div>
                <ZigZag className="center ms-3">
                  <div>
                    {brand === "Apple" && (
                      <img
                        style={{ height: "15vh" }}
                        src={`https://cdn.greenpanda.io/devices/${convertToSlug(
                          brand
                        )}/${convertToSlug(model)}.jpg`}
                        onError={(e) => {
                          e.target.src = process.env.PUBLIC_URL + "/phone.png";
                          e.target.onError = null;
                        }}
                        alt="phone"
                      />
                    )}
                    {brand !== "Apple" && (
                      <img
                        style={{ height: "15vh" }}
                        src={`https://cdn.greenpanda.io/devices/${convertToSlug(
                          brand
                        )}/${convertToSlug(model)}.png`}
                        onError={(e) => {
                          e.target.src = process.env.PUBLIC_URL + "/phone.png";
                          e.target.onError = null;
                        }}
                        alt="phone"
                      />
                    )}
                  </div>
                  <div className="ms-5 ps-5">
                    <div className="mb-5">
                      <div className="fs20 fw600">
                        {brand} {model}
                      </div>
                      {brand === "Apple" && (
                        <div className="fs20 fontGray">
                          {capacity} {color}
                        </div>
                      )}
                    </div>
                    <div className="fs38 lh32 fw600">
                      {state.flow.coin.front}
                      {finalPrice}
                      {state.flow.coin.back}
                    </div>
                  </div>
                </ZigZag>
              </div>
            </div>
          </div>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default withTranslation()(ConfirmPayment);
