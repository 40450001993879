import axios from 'axios';

axios.interceptors.response.use(undefined, (err) => {
  console.log('INTERCEPTORS', JSON.stringify(err));
  const { config, message } = err;
  // console.log(config,message)
  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  // retry while Network timeout or Network Error
  if (
    !(
      message.includes('timeout') ||
      message.includes('Network Error') ||
      err.response.data?.error.includes('timedout')
    )
  ) {
    return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      console.log('retry the request', config.url);
      resolve();
    }, config.retryDelay || 10000);
  });
  return delayRetryRequest.then(() => axios(config));
});

export const get = (path, retries = 1, timeout = 0) => {
  const cancelTokenSource = axios.CancelToken.source();
  const promise = () => {
    return axios.get(`${process.env.REACT_APP_URL}${path}`, {
      cancelToken: cancelTokenSource.token,
      'Content-Type': 'Access-Control-Allow-Origin',
      retry: retries,
      timeout: timeout
    });
  };
  //  cancelTokenSource.cancel();
  return {
    exec: promise,
    cancel: cancelTokenSource.cancel
  };
};

export const post = (params) => {
  let path = params.path;
  let body = params.body ? params.body : {};
  let headers = params.headers
    ? params.headers
    : {
        'Content-Type': 'Access-Control-Allow-Origin'
      };
  let retries = params.retries ? params.retries : 1;
  let timeout = params.timeout ? params.timeout : 0;

  const cancelTokenSource = axios.CancelToken.source();
  const promise = () => {
    return axios.post(`${process.env.REACT_APP_URL}${path}`, body, {
      headers: headers,
      cancelToken: cancelTokenSource.token,
      retry: retries,
      timeout: timeout
    });
  };
  return {
    exec: promise,
    cancel: cancelTokenSource.cancel
  };
};

//state change
export const idleState = (retries = 1, timeout = 0) => {
  let params = {
    path: '/v1/controller/change/idle',
    retries: retries,
    timeout: timeout
  };
  return post(params).exec();
};

//state actions
export const ready = (sessionId) => {
  let params = {
    path: '/v1/controller/change/ready',
    body: { session_id: sessionId },
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return post(params).exec();
};

export const openDrawer = () => {
  let params = {
    path: '/v1/controller/change/opened'
  };
  return post(params).exec();
};

export const evaluation = () => {
  let params = {
    path: '/v1/controller/change/assessment'
  };
  return post(params).exec();
};

export const takePhotos = () => {
  let params = {
    path: '/v1/controller/action/takePhotos'
  };
  return post(params).exec();
};

export const identify = () => {
  let params = {
    path: '/v1/controller/action/identify'
  };
  return post(params).exec();
};

export const pregrade = (retries = 3, timeout = 0) => {
  let params = {
    path: '/v1/controller/action/pregrade',
    retries: retries,
    timeout: timeout
  };
  return post(params);
};

export const completeTransaction = (imei) => {
  let params = {
    path: '/v1/controller/change/idle',
    body: {
      imei: imei
    }
  };
  return post(params).exec();
};

export const dispenseSim = () => {
  let params = {
    path: '/v1/controller/action/dispenseSim'
  };
  return post(params).exec();
};

//Location
export const Location = () => {
  return get('/v1/location', 180, 1800000).exec();
};

//Card reader
export const cardReader = () => {
  return get('/v1/card-reader/read').exec();
};

//Print
let now = new Date();
const curdate = now.toJSON().slice(0, 10).split('-').reverse().join('.');
export const printerPrint = (state) => {
  axios
    .post(`${process.env.REACT_APP_URL}/v1/controller/action/print`, {
      voucherCode: state.userInformation.payment_value,
      localPrice: state.priceInfo.localPrice,
      currencySymbol: state.flow.currencySymbol, //euro symbol
      date: curdate, // This is the date in text format  .. must be formatted by Kiosk UI
      clientEmail: state.userInformation.email,
      language: state.sessionInfo.language,
      partner: {
        name: state.flow.partner.name, // Partner name
        code: state.flow.partner.code, // flow code
        expiration: '6'
      }
    })
    .then((res) => {
      console.log(res);
    })
    .catch((res) => {
      console.log(res);
    });
};
