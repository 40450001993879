import { Trans } from 'react-i18next';
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../utils/context';
import {
  MainLayout,
  Top,
  Center,
  Bottom
} from '../../layouts/MainLayout/MainLayout';
import { MainTitle, RadioButton } from '../../components';

const OperatingSystem = (props) => {
  const appContext = useContext(AppContext);
  const { dispatch } = appContext;
  const [os, setOs] = useState();

  useEffect(() => {
    dispatch({ type: 'userJourney', payload: 'operating-system' });
    dispatch({ type: 'progress', payload: 'step3' });
  }, [dispatch]);

  return (
    <>
      <MainLayout
        progress_bar
        exit
        nextButton={{
          text: <Trans i18nKey="next_button" />,
          to:
            process.env.PUBLIC_URL +
            `/timer/factory-settings/instructions/${os}`
        }}
        backButton={{
          to: process.env.PUBLIC_URL + '/timer/factory-settings'
        }}
      >
        <Top></Top>
        <Center>
          <div>
            <MainTitle className="mb-5 pb-5 text-center">
              <Trans i18nKey="operating_system.Your smartpnone runs..." />
            </MainTitle>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <RadioButton
              text="operating_system.ios"
              selected={os === 'ios'}
              image={`OperatingSystem/ios.svg`}
              onClick={() => {
                setOs('ios');
              }}
            />
            <RadioButton
              text="operating_system.android"
              selected={os === 'android'}
              image={`OperatingSystem/android.svg`}
              onClick={() => {
                setOs('android');
              }}
            />
          </div>
        </Center>
        <Bottom></Bottom>
      </MainLayout>
    </>
  );
};

export default OperatingSystem;
