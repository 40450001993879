import React from "react";
import styled from "styled-components";
import { Link } from "@gatsbyjs/reach-router";

const LinkStyled = styled(Link)`
  text-decoration: none;
`;
const Button = styled.div`
    min-width: ${(props) => props.width};
    max-width 420px;
    height: 12.5vh;
    max-height: 130px;
    padding: 0 3vw;
    background: ${(props) =>
      !props.secondary
        ? ({ theme: { primaryButton } }) =>
            !props.disabled ? primaryButton.bg : primaryButton.disabled
        : ({ theme: { secondaryButton } }) => secondaryButton.bg};
    color: ${(props) =>
      !props.secondary
        ? ({ theme: { primaryButton } }) => primaryButton.clr
        : ({ theme: { secondaryButton } }) => secondaryButton.clr};
    border-radius: ${({ theme: { primaryButton } }) => primaryButton.radius};
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 3.8rem;
    border:${(props) => (!props.secondary ? "none" : "2px solid #F2F2F2")};
    &:active {
        box-shadow: none;
    }
    display:flex;
    justify-content: center;
    align-items:center;
`;

const ActionButton = (props) => {
  const { t, i18n } = props;

  return (
    <LinkStyled
      replace={true}
      className={props.className}
      to={!props.onClick ? (!props.disabled ? props.to || "/" : "#") : "#"}
      onClick={props.onClick}
    >
      <Button
        style={props.style}
        className={props.className}
        secondary={props.secondary}
        disabled={props.disabled}
        width={props.width}
        bg={props.bg}
        clr={props.clr}
      >
        {props.children}
      </Button>
    </LinkStyled>
  );
};

export default ActionButton;
