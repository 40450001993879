import React from "react";
import { useContext } from "react";
import { AppContext } from "../../utils/context";

const MainVideo = (props) => {

    const appContext = useContext(AppContext);
    const { state } = appContext;

    return (
        <div style={props.style} className={props.className} onClick={props.onClick}>
            {!props.loop ? <video style={{ width: props.width, maxHeight: props.height }} autoPlay muted>  <source src={`images/${state.flow.theme}/${props.video}`} type="video/mp4" /></video>
                : <video autoPlay muted loop style={{ width: props.width, maxHeight: props.height, maxWidth:props.maxWidth, height:props.absHeight, boxShadow: !props.boxShadow ? 'none' : '0px 4px 16px 0px rgba(0, 0, 0, 0.1)' }}>  <source src={`images/${state.flow.theme}/${props.video}`} type="video/mp4" /></video>}
        </div>
    );
}

export default MainVideo;