import { Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainImage, MainCard, MainTitle, ActionButton, Popup, InfoButton } from '../../components';


const TurnOnDevice = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [showBattery, setShowBattery] = useState(false);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'turn-on-device' })
        dispatch({ type: "progress", payload: 'step1' })
        dispatch({ type: "percent", payload: 14 })
    }, []);


    return (
        <>
            <MainLayout
                progress_bar
                exit
                nextButton={{
                    text: <Trans i18nKey="next_button" />,
                    to: process.env.PUBLIC_URL + (state.sessionInfo.flow === 'trade_in' ? state.flow.turn_on_device.next_button.trade_in : state.flow.turn_on_device.next_button.quick_trade_in),
                }}
                backButton={{ to: process.env.PUBLIC_URL + '/' }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='center'>
                        <MainImage style={{ marginRight: '6vw' }} img='TurnOnDevice/turn_on_device.png' height='25vh' />
                        <div>
                            <MainTitle className='mb-5'><Trans i18nKey="turn_on_device.title" /></MainTitle>
                            <InfoButton className='me-5' onClick={() => setShowBattery(true)}><Trans i18nKey="turn_on_device.info_button" /></InfoButton>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
            <Popup show={showBattery} onHide={() => setShowBattery(false)}>
                <div style={{ width: '50vw' }} className='d-flex flex-column align-items-center'>
                    <div className='text-center fs30 fw600 mt-4'><Trans i18nKey="popup_battery.title" /></div>
                    <div style={{ width: '48vw' }} className='d-flex align-items-center my-5 pb-5'>
                        <MainImage img='Start/battery.png' height='13vh' />
                        <div>
                            <div className='ms-5 ps-2 fs20 fw600 lh26 mb-4'><Trans i18nKey="popup_battery.subtitle1" /></div>
                            <div className='ms-5 ps-2 fs20 lh26'><Trans i18nKey="popup_battery.subtitle2" />🌱</div>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <ActionButton className='mx-4' onClick={() => setShowBattery(false)} to='#' width='26vw'><Trans i18nKey="done" /></ActionButton>
                    </div>
                </div>
            </Popup>
        </>
    );
}

export default TurnOnDevice;