import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainImage, MainVideo, MainCard, MainTitle, ActionButton, Popup, InfoButton, QRPopup, BorderQRCode } from '../../components';
import QRCode from "react-qr-code";
import styled from "styled-components";
import axios from 'axios';


const BorderBottom = styled.span`
  border-bottom: 8px solid ${({ theme: { colors } }) => colors.light_third};
  font-weight: 600;
`;

const SimQR = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [simQRCode, setSimQRCode] = useState(false);

    useEffect(() => {
        console.log('ESIM')
        axios.get(`https://session-service.greenpanda.io/esims/pop?sessionid=${state.sessionInfo.uuid}`, { 'Content-Type': 'application/json; charset=utf-8' })
            .then((res) => {
                console.log(res.data.url);
                setSimQRCode(res.data.url)
            })
            .catch(function (error) {
                console.log(error);
            });
        setLoading(true);
        dispatch({ type: "userJourney", payload: 'sim-qr' })
        dispatch({ type: "progress", payload: 'step2' })
        // TODO: handle clean up
        setTimeout(() => {
            setLoading(false);
        }, 4000);
    }, []);

    // const cameraDoesntWork = () => {
    //     navigate(process.env.PUBLIC_URL + '/open-drawer', { replace: true })
    //     dispatch({ type: "sessionInfo", payload: { camera: false } })
    // }

    return (
        <>
            <MainLayout
                exit
                progress_bar
                nextButton={{
                    text: <Trans i18nKey="next_button" />,
                    to: process.env.PUBLIC_URL + state.flow.sim_qr.next_button,
                    disabled: loading
                }}
                backButton={{ to: process.env.PUBLIC_URL + state.flow.sim_qr.back_button }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='align-items-center'>
                        {simQRCode && <div className='d-flex justify-content-center align-items-center' style={{ width: '400px', height: '400px' }}>
                            <BorderQRCode><QRCode size={150} value={simQRCode} /></BorderQRCode>
                        </div>}
                        {!simQRCode && <MainVideo video='SimQR/sim_qr.mp4' />}
                        <div>
                            <MainTitle style={{ width: '48vw' }}><BorderBottom><Trans i18nKey="sim_qr.border_bottom" /> </BorderBottom><Trans i18nKey="sim_qr.title" /></MainTitle>
                            <div className='d-flex'>
                                <InfoButton className='mt-5 me-5' onClick={() => setShow(true)}><Trans i18nKey="sim_qr.info_button" /></InfoButton>
                                {/* <InfoButton onClick={() => !loading && navigate(process.env.PUBLIC_URL + '/get-sim', { replace: true })}>My device doesn’t support eSIM</InfoButton> */}
                            </div>
                        </div>
                        {/* <Notification loading={loading}><u onClick={() => cameraDoesntWork()}><Trans i18nKey="sim_qr.notification" /></u></Notification> */}
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
            <QRPopup show={show} onHide={() => setShow(false)} />
            <Popup show={showError} onHide={() => setShowError(false)}>
                <div className='d-flex flex-column align-items-center'>
                    <MainTitle className='text-center mt-4'></MainTitle>
                    <div className='d-flex align-items-center my-5 pb-5'>
                        <MainImage img='SimQR/get_sim.png' height='13vh' />
                        <div className='ms-5 fs20 lh26'></div>
                    </div>
                    <div className='d-flex'>
                        <ActionButton className='mx-4' onClick={() => setShowError(false)} to='#' secondary width='26vw'></ActionButton>
                        <ActionButton className='mx-4' to={process.env.PUBLIC_URL + '/timer/get-sim'} width='27vw'></ActionButton>
                    </div>
                </div>
            </Popup>
        </>
    );
}

export default withTranslation()(SimQR);